<template>
  <div class="finish pagefull">
    <div class="finish__container">
      <div
        class="finish__container__background"
        :style="{ backgroundImage: `url(${getURL(background)})` }"
      >
        <div>
          <div>
            <div class="finish__container__image">
              <img :src="getURL(image)" :alt="title" />
            </div>
            <div class="finish__container__text">
              <h2>{{ title }}</h2>
              <p>{{ subtitle }}</p>
              <div v-if="loading">Loading...</div>
            </div>
          </div>
          <div v-if="imagePreview">
            <div class="preview_container">
              <div class="preview_wrap">
                <img
                  
                  class="preview"
                  :src="imagePreview"
                  alt="recording preview"
                />
              </div>
              <div
                id="downloadButton"
                class="downloadBut preview_wrap"
                :onClick="onClickDownload"
              >
                <img
                  :src="getURL('images/Icon/Download.png')"
                  :srcset="
                    require('@/assets/images/Icon/Download@2x.png') + ' 2x'
                  "
                  alt="download"
                />
                <h3>Save Highlight</h3>
              </div>
            </div>
          </div>
          <div>
            <v-button
              id=""
              :onClick="onClickReplay"
              kind="button__tertiary"
              class="button__replay"
            >
              <img
                :src="require('@/assets/images/Icon/replay.svg')"
                alt="Reload"
              />
              Play Again!
            </v-button>
          </div>
        </div>
      </div>
    </div>
    <v-button-ball :onClick="onClickTurn" class="ball">
      <template v-slot:header>{{ player }}</template>
      Turn
    </v-button-ball>
  </div>
</template>

<script>
import Button from "@/components/Button";
import ButtonBall from "@/components/ButtonBall";

export default {
  data: function () {
    return {
      imagePreview: null,
      dataType: null,
      loading: true,
    };
  },
  props: {
    title: String,
    subtitle: String,
    player: String,
    image: String,
    background: String,
    downloadshow: {
      type: Boolean,
      default: false,
    },
    onClickReplay: {
      type: Function,
      default: () => {},
    },
    onClickTurn: {
      type: Function,
      default: () => {},
    },
    onClickDownload: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    "v-button": Button,
    "v-button-ball": ButtonBall,
  },
  mounted() {
    setTimeout(
      () => window.dispatchEvent(new CustomEvent("mediarecorder-initBut")),
      1
    );

    try {
      console.log("window.renderCapture.image    ", window.renderCapture.image);

      setTimeout(() => {
        this.loading = false;
        this.imagePreview = window.renderCapture.image;
      }, 2000);
    } catch (e) {
      console.log("ERROR window.renderCapture.image");
    }
  },
  unmounted() {},
  methods: {
    getURL(image) {
      return require("@/assets/" + image);
    },
  },
};
</script>

<style lang="scss" scoped>
.finish {
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .preview_container {
    display: flex;
    height: 230px;
    width: 90%;
    margin: 0 auto;
  }
  .downloadBut {
    pointer-events: auto;
  }
  .preview {
    width: 80%;
  }
  .imagePreview {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 15px 20px;
    width: 100%;
  }
  .preview_wrap {
    flex: 50%;
    text-align: center;
    /* width: 80%; */
    margin: 0 auto;
  }
  .ball {
    pointer-events: auto;
  }

  .button__replay {
    pointer-events: auto;
  }

  &__container {
    align-items: center;
    display: flex;
    height: 450px;
    justify-content: flex-end;
    margin-bottom: 20px;

    &__background {
      align-self: center;
      background-position: center;
      background-repeat: no-repeat;
      height: 435px;
      width: 330px;

      > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 15px;
        width: 100%;

        > div {
          display: flex;
          min-height: 138px;

          &:nth-child(2) {
            min-height: 235px;
          }

          &:last-child {
            align-items: center;
            height: 100%;
            min-height: auto;
            justify-content: center;
          }
        }
      }
    }

    &__image {
      margin-left: -16px;
      margin-top: -24px;
    }

    &__text {
      margin-top: 20px;
      text-align: initial;

      * {
        text-align: initial;
      }

      > div {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        text-align: center;

        > div {
          text-align: center;
        }
      }
    }
  }
}
</style>
