<template>
  <button class="button-icon">
    <slot>button</slot>
  </button>
</template>

<script>
export default {};
</script>

<style lang="scss">
.button-icon {
  align-items: center;
  background-color: $dodgerBlue;
  border: none;
  border-radius: 50%;
  color: $white;
  display: flex;
  justify-content: center;
}
</style>