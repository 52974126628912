<template>
  <div class="carousel viewport" ref="viewport">
    <h1>{{ list[0] && list[0].title }}</h1>
    <transition name="carousel__fade">
      <div class="carousel__swipe" v-show="showHelp">
        <h3>swipe</h3>
        <div class="arrowAnim">
          <div class="arrowSliding">
            <div class="arrow"></div>
          </div>
          <div class="arrowSliding delay1">
            <div class="arrow"></div>
          </div>
          <div class="arrowSliding delay2">
            <div class="arrow"></div>
          </div>
          <div class="arrowSliding delay3">
            <div class="arrow"></div>
          </div>
        </div>
      </div>
    </transition>
    <div class="list-container" ref="listContainer">
      <ul class="list" ref="list">
        <li class="list-item" :key="item.id" v-for="item in list">
          <div
            class="multiselect__card"
            :class="`multiselect__card-${item.id}`"
            :style="[item.left ? {'left': item.left} : {'left': ''}]"
            @click="onSelect(item)"
          >
            <div class="multiselect__card__container">
              <div class="multiselect__card__container__image">
                <img
                  :src="getURL(item.image)"
                  :srcset="getSecondURL(item.image) + ' 2x'"
                  :alt="item.name"
                />
              </div>
              <div
                class="multiselect__card__container__background"
                :style="{ backgroundImage: `url(${getURL(item.background)})` }"
              >
                <div class="multiselect__card__container__text">
                  <h2>{{ item.name }}</h2>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";

export default {
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
      showHelp: true,
    };
  },
  computed: {
    count() {
      return this.list.length;
    },
  },
  methods: {
    getURL(image) {
      return require("@/assets/" + image);
    },
    getSecondURL(image) {
      const current =
        image.substring(0, image.lastIndexOf(".")) +
        "@2x" +
        image.substring(image.lastIndexOf("."));

      return require("@/assets/" + current);
    },
  },
  mounted() {
    this.list = [...this.cards];
    // this.list.unshift(this.list.pop());

    setTimeout(() => {
      this.showHelp = false;
    }, 2500);

    setTimeout(() => {
      // const proxy = document.createElement("div");
      const items = this.$refs.list.querySelectorAll("li");
      const viewWidth = Math.min(window.innerWidth, 480);
      const itemWidth = (viewWidth * 95) / 100;
      const wrapWidth = this.count * itemWidth;
      // const wrapVal = gsap.utils.wrap(0, wrapWidth);

      gsap.registerPlugin(Draggable, InertiaPlugin);

      // gsap.set(this.$refs.list, {
      //   left: -itemWidth,
      // });

      items.forEach((item, i) => {
        gsap.set(item, {
          x: i * itemWidth,
          width: itemWidth,
        });
      });

      // const animation = gsap.to(items, {
      //   duration: 1,
      //   x: `+=${wrapWidth}`,
      //   ease: "none",
      //   paused: true,
      //   modifiers: {
      //     x: function (x, target) {

      //       // x = parseInt(x) % wrapWidth;
      //       console.log(target, 'x   >>>>>>', x)
      //       if(wrapWidth){
      //         wrapWidth
      //       }
      //       target.style.visibility =
      //         x - itemWidth > viewWidth ? "hidden" : "visible";
      //       return `${x}px`;
      //     },
      //     // x: function (x, target) {
      //     //   x = parseInt(x) % wrapWidth;
      //     //   console.log('x   >>>>>>', x)
      //     //   target.style.visibility =
      //     //     x - itemWidth > viewWidth ? "hidden" : "visible";
      //     //   return `${x}px`;
      //     // },
      //   },
      // });

      Draggable.create(this.$refs.list, {
        type: "x",
        trigger: this.$refs.listContainer,
        inertia: true,
        // onDrag: updateProgress,
        // onThrowUpdate: updateProgress,
        bounds: {minX: -(wrapWidth-(itemWidth+10)), minY: 0, maxX: 0, maxY: 200},
        snap: {
          x: (x) => {
            console.log()
            //multiselect__card-2
            return Math.round(x / itemWidth) * itemWidth;
          },
        },
      });
      // Draggable.create("#timeline", {type:"x", edgeResistance:1, lockAxis:true, bounds:window-1000})[0];
      // function updateProgress() {
      //   // animation.progress(wrapVal(this.x) / wrapWidth);
      // }
    }, 4);
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  @include breakpoint(640px, "from") {
    max-width: 480px;
  }

  h1 {
    color: $white;
    margin-top: 40px;
  }

  &__swipe {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-70px);
    z-index: 9999;
    width: 53px;
    height: 73px;
    background: $froastedFlakesOrange;
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include breakpoint(640px, "from") {
      top: 20%;
      transform: none;
    }

    h3 {
      margin-bottom: 10px;
    }
  }

  &__fade-enter-active,
  &__fade-leave-active {
    transition: opacity 0.5s ease-in-out 7s;
  }

  &__fade-enter,
  &__fade-leave-to {
    opacity: 0;
  }
}
.viewport {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background-color: #333;
  overflow: hidden;

  .list-container {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  }

  .list {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 80%;
    font-size: 62px;
    line-height: 1;
    margin-bottom: 24px;
    color: #fff;

    .list-item {
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
    }
  }
}
.multiselect__card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // min-width: 75%;
  // margin: auto 0.85em auto 0.85em;
  top: 0;
  max-height: 500px;
  transition: height;
  // height: 80%;
  // margin: auto 0.85em auto 0;
  // min-width: 90%;
  // width: 100%;

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: flex-end;
    // overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
    }

    &__background {
      align-self: flex-end;
      background-repeat: no-repeat;
      background-position: center right;
      // box-shadow: 2px 2px 15px 1px;
      // height: 100%;
      // width: 90%;
      background-size: 317px 453px;
      height: 100%;
      width: 100%;
      background-position-y: 35px;
      background-position-x: 56px;
    }

    &__text {
      margin-top: 100px;
      margin-left: 50%;
      max-width: 40%;
      position: absolute;
      text-align: left;

      h2 {
        text-align: left;
      }
    }

    &__image {
      left: 0;
      position: absolute;
      top: -40px;

      img {
        // width: 100%;
      }
    }
  }
}
.arrowAnim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 5px;
  height: 5px;
  border: 1px solid;
  border-color: $white transparent transparent $white;
  transform: rotate(135deg);
}

.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite;
  animation: slide 4s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.delay2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.delay3 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-5px);
  }
  20% {
    opacity: 1;
    transform: translateX(-2px);
  }
  80% {
    opacity: 1;
    transform: translateX(2px);
  }
  100% {
    opacity: 0;
    transform: translateX(5px);
  }
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  20% {
    opacity: 1;
    transform: translateX(-5px);
  }
  80% {
    opacity: 1;
    transform: translateX(5px);
  }
  100% {
    opacity: 0;
    transform: translateX(10px);
  }
}
</style>
