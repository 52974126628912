<template>
  <a :href="href">
    <slot>Link</slot>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: false,
    },
  },
};
</script>

<style>
</style>