<template>
  <div class="Header">
    <img
      class="reset activate"
      :src="require('@/assets/images/reset.png')"
      alt="1"
      :onClick="reCenter"
    />
    <div class="photoNote">Great Photo</div>
    <img
      class="close activate"
      :src="require('@/assets/images/close.png')"
      alt="1"
      :onClick="close"
    />
  </div>
</template>

<script>

import router from '../router/index'

export default {
  name: "Header",
  data: function() {
    return {
      scene: null
    };
  },
  methods: {
    reCenter: function() {
      try {
        this.scene.emit("recenter", {});
      } catch (e) {
        console.log("ERROR");
      }
    },
    close: function() {
      try {
        router.push("exit");
      } catch (e) {
        console.log("ERROR");
      }
    }
  },
  mounted: function() {
    this.scene = document.getElementById("scene");
  }
};
</script>
<style scoped lang="scss">
.Header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}
.activate {
  pointer-events: auto;
}
.photoNote {
  background: rgb(0 0 0 / 33%);
    border-radius: 6px;
    -webkit-backdrop-filter: blur(23px);
    backdrop-filter: blur(23px);
    font-size: 1.2rem;
    color: #fff;
    padding: 10px;
    opacity:0
}
</style>
