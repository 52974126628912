<template>
    <transition
      appear
      v-bind:css="false"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:leave="leave"
    >
      <div class="Share">
        <v-download-overlay class="downloadhint"/>
        <v-finish
          :title="headline"
          :subtitle="subtitle"
          :player="playername"
          :onClickReplay="playAgain"
          :onClickTurn="playerTurn"
          :onClickDownload="download"
          :downloadshow="downloadBut"
          :image="state.player === 0 ? 'images/Photo/tony_finish.png' : 'images/Photo/shaq_finish.png'"
          background="images/Photo/finish_bg.png"
        />
      </div>
    </transition>

</template>
<style lang="scss" scoped>
.Brand {
  //   position: fixed;
  //   width:80%;
  //   margin:0 auto;
  // bottom: 0;
  // z-index:2000;
  position: absolute;
  bottom: -90px;
  align-self: flex-end;
  justify-self: flex-end;
}
.Select {
  text-align: center;
  width: 100%;
  pointer-events: auto;
}
#countdown {
  position: absolute;
  text-align: center;
  top: 85px;
  width: 100%;
  font-size: 10rem;

  z-index: 2000;
}
.downloadhint {
  z-index: 3000;
}
</style>
<script>
// @ is an alias to /src
import { gsap } from "gsap";

import router from "../router/index";
import store from "../Store";
import FinishGame from "@/components/FinishGame";
import DownloadOverlay from "@/components/DownloadOverlay";

export default {
  name: "Select",
  components: {
    "v-finish": FinishGame,
    "v-download-overlay": DownloadOverlay,
  },
  data: function () {
    return {
      init: gsap.timeline(),
      state: store.state,
      playername: "Shaq's",
      downloadBut: true,
      subtitle: '',
      headerImage: '',
      showMessage: false
    };
  },
  mounted() {
    console.log('MultiSelect mounted!')
    window.webar_TTT.tracking.track_gamestate('share');
    this.copy();
    window.addEventListener('mediarecorder-recordcomplete', this.showDownload)
    gsap.set('.downloadhint', {autoAlpha:0})

    console.log('this.downloadBut   ', this.downloadBut);
    console.log('(window.renderCapture.video === null || window.renderCapture.image === null)   ', (window.renderCapture.video === null || window.renderCapture.image === null));
  },
  unmounted() {
    window.removeEventListener(
      "mediarecorder-recordcomplete",
      this.showDownload
    );
  },
  updated() {
    console.log("updated!");
  },
  methods: {
    copy(){
      let type = this.state.capture;
      this.headerImage = this.state.player === 0 ? "images/Photo/tony_finish.png" : "images/Photo/shaq_finish.png";
      if(type === 'video'){
        this.headline = this.state.player === 0 ? "Way to go, Tiger!" : "Way to go, Shaq!";
        this.subtitle = 'Save your highlight reel and share it with the world using #TonyVsShaq';
      } else if(type === 'image') {
        this.headline = this.state.player === 0 ? "Nice shot, Tiger!" : "Nice shot, Shaq!";
        this.subtitle = 'Save your epic pic and share it with the world using #TonyVsShaq';
      } else {
        this.headline = this.state.player === 0 ? "Good work, Tiger!" : "Good work, Shaq!";
        this.subtitle = 'Don’t stop now. Capture some highlights and share it with the world using #TonyVsShaq';
      }
    },
    showDownload() {
      // this.downloadBut = true;
    },
    beforeEnter(el) {
      if (this.state.player === 0) {
        this.playername = "Shaq's";
      } else {
        this.playername = "Tony's";
      }
      if (this.title) {
        // user takes photo
        this.title = "Nice shot, Tiger!";
        this.message =
          "Save your epic pic and share it with the world using #TonyVsShaq";
      } else if (this.message) {
        // user captures video
        this.title = "Way to go, Tiger!";
        this.message =
          "Save your highlight reel and share it with the world using #TonyVsShaq";
      } else {
        // user does neither
        this.title = "Good work, Tiger.";
        this.message =
          "Don’t stop now. Capture some highlights and share it with the world using #TonyVsShaq";
      }
      // gsap.set(el, { opacity: 0 });
      console.log("beforeEnter el  ", el);
    },
    enter(el, done) {
      // gsap.to(el, 1, { opacity:1, onComplete:done });
      console.log("enter el  ", el);
      done();
    },
    leave(el, done) {
      // gsap.to(el, 1, { opacity:0, onComplete:done });
      console.log("leave el  ", el);
      done();
    },
    playAgain: function () {
      console.log("JUMP TO SELECT AGAIN");
      window.webar_TTT.tracking.track_action('share_playagain');
      router.push("play");
    },
    playerTurn: function () {
      console.log("JUMP TO SELECT AGAIN", this.state.player);
      window.webar_TTT.tracking.track_action('share_next_dunk');
      if (this.state.player === 0) {
        store.player = 1;
      } else {
        store.player = 0;
      }
      router.push("select");
    },
    download: function () {
      console.log("JUMP TO SELECT AGAIN", this.state.player);
      window.webar_TTT.tracking.track_action('share_download');
      this.showMessage = true;
      gsap.to('.downloadhint', {duration: 0.5, autoAlpha:1, onComplete: function(){
        console.log('animationcompleted');
        gsap.to('.downloadhint', {duration: 0.5, autoAlpha:0, delay: 1});
        window.dispatchEvent(new CustomEvent("mediarecorder-download"));
      }})
    },
  },
};
</script>
