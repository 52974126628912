import { Group } from "three";
import * as THREE from "three";
import { RAND, convertColorToPoint } from "../helper";

import Particle from "./Particle.js";

export default class Fireworks extends Group {
  constructor() {
    super();

    this.gravity = new THREE.Vector3(0, (-2 / 10000), 0);
    this.fireworks = null;
    // console.log('this', this)
    this.exploded = false;
    this.ExplodeArr = [];
    this.play = false;
    this.nExplodeParticles = 100;
    this.done = false;
  }
  display(){
    this.fireworks = new Particle(RAND(0, 4), 0, 0, true);
  }

  reset() {
    this.done = true
    this.ExplodeArr = null;
  }
  update() {
    if(this.done){
      return;
    }
    if (!this.exploded) {
      this.fireworks.applyForce(this.gravity);
      this.fireworks.show();
      this.fireworks.update();
      if (this.fireworks.velocity.y <= 0) {
        this.explode({x: this.fireworks.pos.x, y: this.fireworks.pos.y, z:this.fireworks.pos.z})
        this.remove(this.fireworks);
        this.fireworks = null;
      }
    }
    for(let i=0; i < this.ExplodeArr.length; i++){
      this.ExplodeArr[i].applyForce(this.gravity);
      this.ExplodeArr[i].update();
      if (this.ExplodeArr[i].done) {
        // console.log('this.particles[i].velocity.y  ', this.particles[i].velocity.y);
        this.remove(this.ExplodeArr[i])
        this.ExplodeArr.splice(i, 1)
      }
    }
    if(this.ExplodeArr.length === 0){
      this.reset();
    }
  }

  explode(pos) {
    this.exploded = true;
    // console.log('explode   >>>>>>>>>>>');
    var color = new THREE.Color();
    let minArr = [30, 175];
    let maxArr = [60, 220];
    let selectArr = Math.random() < 0.5 ? 0 : 1;
    var cMin = convertColorToPoint(minArr[selectArr]);//30
    var cMax = convertColorToPoint(maxArr[selectArr]);//60
    color.setHSL(THREE.Math.randFloat(cMin, cMax), 1, 0.5 );
    for(let i=0; i < this.nExplodeParticles; i++){
      let p = new Particle(pos.x, pos.y, pos.z, color, false);
      this.ExplodeArr.push(p)
      this.add(p);
    }
    // console.log('this.particles    ', this.particles);
  }
  show() {
    if(!this.done){
      if (!this.exploded) {
        this.add(this.fireworks);
      }
      for(let i=0; i < this.ExplodeArr.length; i++){
        this.ExplodeArr[i].show();
      }
    }
  }
}
