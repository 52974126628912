
var store = {
  debug: false,
  motionPrompt: false,
  state: {
    player: 0,
    dunk: null,
    static: null,
    capture: null,
    slowmo: .99,
    landscape: false,
    group: -1,
    tony_talk_selected: 0,
    shaq_talk_selected: 0,
    tonyTalkArr: [{ pre: 'TONY_BS1.mp3', post: 'TONY_AS1.mp3' }, { pre: 'TONY_BS2.mp3', post: 'TONY_AS2.mp3' }, { pre: 'TONY_BS3.mp3', post: 'TONY_AS3.mp3' }, { pre: 'TONY_BS4.mp3', post: 'TONY_AS4.mp3' }, { pre: 'TONY_BS5.mp3', post: 'TONY_AS5.mp3' }, { pre: 'TONY_BS6.mp3', post: 'TONY_AS6.mp3' }],
    shaqTalkArr: [{ pre: 'SHAQ_BS1.mp3', post: 'SHAQ_AS1.mp3' }, { pre: 'SHAQ_BS2.mp3', post: 'SHAQ_AS2.mp3' }, { pre: 'SHAQ_BS3.mp3', post: 'SHAQ_AS3.mp3' }, { pre: 'SHAQ_BS4.mp3', post: 'SHAQ_AS4.mp3' }, { pre: 'SHAQ_BS5.mp3', post: 'SHAQ_AS5.mp3' }, { pre: 'SHAQ_BS6.mp3', post: 'SHAQ_AS6.mp3' }, { pre: 'SHAQ_BS7.mp3', post: 'SHAQ_AS7.mp3' }, { pre: 'SHAQ_BS8.mp3', post: 'SHAQ_AS8.mp3' }],
    talk_active: null
  },
  set player(value) {
    if (this.debug) console.log('store.player action triggered', value)
    this.state.player = value;
  },
  set dunk(value) {
    if (this.debug) console.log('store.dunk action triggered', value)
    this.state.dunk = value;
  },
  set static(value) {
    if (this.debug) console.log('store.static action triggered', value)
    this.state.static = value;
  },
  set capture(value) {
    if (this.debug) console.log('store.capture action triggered', value)
    this.state.capture = value;
  },
  set slowmo(value) {
    if (this.debug) console.log('store.slowmo action triggered', value)
    this.state.slowmo = value;
  },
  set landscape(value) {
    if (this.debug) console.log('store.landscape action triggered', value)
    this.state.landscape = value;
  },
  set group(value) {
    if (this.debug) console.log('store.group action triggered', value)
    this.state.group = value;
  },
  set tony_talk_selected(value) {
    if (this.debug) console.log('store.tony_talk_selected action triggered', value)
    this.state.tony_talk_selected = value;
  },
  set shaq_talk_selected(value) {
    if (this.debug) console.log('store.shaq_talk_selected action triggered', value)
    this.state.shaq_talk_selected = value;
  },
  set talk_active(value) {
    if (this.debug) console.log('store.talk_active action triggered', value)
    this.state.talk_active = value;
  }
}

window.store = store;
export default store;