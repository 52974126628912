<template>
  <button @click="onClick" :type="type" :class="'button ' + kind">
    <div v-if="kind === 'button__primary'">
      <span class="text-light">
        <slot name="header"></slot>
      </span>
      <span class="text-black">
        <slot>Button</slot>
      </span>
    </div>
    <span class="text-light" v-if="kind !== 'button__primary'">
      <slot name="header"></slot>
    </span>
    <span class="text-black" v-if="kind !== 'button__primary'">
      <slot>Button</slot>
    </span>
    <img v-if="kind === 'button__primary'" :src="Stripes" alt="" />
  </button>
</template>

<script>
import Stripes from "@/assets/images/CTA/Stripes.svg";

export default {
  components: {},
  setup() {
    return {
      Stripes,
    };
  },
  props: {
    onClick: {
      type: Function,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "button",
    },
    kind: {
      type: String,
      required: false,
      default: "button__primary",
    },
  },
};
</script>

<style lang="scss">
.button {
  border: none;
  color: $white;
  display: block;
  font-family: inherit;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 32px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  pointer-events: auto;

  &.button__primary {
    background-color: $froastedFlakesOrange;
    color: $white;
    height: 56px;
    position: relative;

    > div {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1000;

      span:first-child {
        margin-right: 5px;
      }
    }

    img {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }

    span {
      z-index: 3;
    }
  }

  &.button__secondary {
    background: transparent;
    border: 1px solid $white;
    color: $white;
    height: 56px;
  }

  .text-light {
    // font-family: MissionGothic-LightItalic;
    font-weight: 200;
    font-style: italic;
    text-decoration: none;
  }

  .text-black {
    // font-family: MissionGothic-BlackItalic;
    font-weight: bold;
    font-style: italic;
  }

  &.button__tertiary {
    background: transparent;
    border: 1px solid $white;
    color: $white;
    font-size: 14px;

    .text-black {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 3px;
    }
  }

  &.button__quaternary {
    background: transparent;
    // background: $dodgerBlue;
    // border: 1px solid $white;
    // border-radius: 10px;
    font-size: 16px;
    font-style: normal;
    padding: 0;
    width: auto;

    .text-black {
      font-style: normal;
    }
  }
}
</style>