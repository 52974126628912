<template>
  <canvas id="root"></canvas>
  <router-view />
</template>

    

<script>

export default {
  created () {
      document.title = 'Tony The Tiger';
  }
}
</script>
<style lang="scss">
@import './scss/app.scss';
</style>