import { Group } from "three";
import * as THREE from "three";
import PIMAGE from "./images/particle2.png";
import { RAND } from "../helper";
THREE.Vector3.prototype.randomUnitVector = function () {
  this.x = (Math.random() * 2 - 1) / 10;
  this.y = (Math.random() * 2 - 1) / 10;
  this.z = (Math.random() * 2 - 1) / 10;
  // console.log('this.x ', this.x, 'this.y', this.y, 'this.z', this.x);
  // this.normalize();
  return this;
};
export default class Particle extends Group {
  constructor(x, y, z, color, firework) {
    super();

    this.name = "particle";
    this.firework = firework;
    this.particles = null;
    this.pos = new THREE.Vector3(x, y, z);
    this.lifespan = 255;
    this.done = false;
    // this.velocity = new THREE.Vector3(0, RAND(10/1000, 50/1000), 0);
    if (firework) {
      this.velocity = new THREE.Vector3(0, RAND(10 / 1000, 50 / 1000), 0);
      console.log(
        "this.velocity   ",
        this.velocity.x,
        this.velocity.y,
        this.velocity.z
      );
    } else {
      this.velocity = new THREE.Vector3().randomUnitVector();
      // console.log('this.velocity   ', this.velocity.x, this.velocity.y, this.velocity.z);
    }
    this.accretion = new THREE.Vector3(0, 0, 0);

    this.texture = new THREE.TextureLoader().load(PIMAGE);
    this.pMaterial = new THREE.PointsMaterial({
      color: color ? color : 0xffffff,
      size: 0.3,
      map: this.texture,
      // blending: THREE.AdditiveBlending,
      depthTest: false,
      transparent: true,
    });

    // size: 16,
    // color: 0xffffff,
    // opacity: 1,
    // vertexColors: true,
    // transparent: true,
    // depthTest: false,
    this.particleX = this.buildParticles();
  }
  applyForce(force) {
  if (!this.end) {
      this.accretion.x += force.x;
      this.accretion.y += force.y;
      this.accretion.z += force.z;
    }
  }

  buildParticles() {
    const points = [];
    points.push(this.addParticle());
    // this.particles.vertices.push(this.addParticle(0, 0));
    this.particles = new THREE.BufferGeometry().setFromPoints( points );
    let particleSystem = new THREE.Points(this.particles, this.pMaterial);
    return particleSystem;
  }

  addParticle() {
    // create a particle with random
    // position values, -250 -> 250
    var vertex = new THREE.Vector3();
    vertex.x = this.pos.x; //this.rand(min, max);
    vertex.y = this.pos.y; //this.rand(min, max);
    vertex.z = this.pos.z; //this.rand(min, max);
    return vertex;
  }
  animateParticle() {
    //     // add it to the geometry
    this.velocity.x += this.accretion.x;
    this.velocity.y += this.accretion.y;
    this.velocity.z += this.accretion.z;

    this.pos.x += this.velocity.x;
    this.pos.y += this.velocity.y;
    this.pos.z += this.velocity.z;
    //     //this.particleObjArr.push(point)
    //     // console.log('this.particles.vertices[i]   ', this.particles.vertices[i]);
    let posAtt = this.particles.attributes.position.array;
    // console.log('this.particles.position ', this.particles, posAtt)
    posAtt[0] = this.pos.x;
    posAtt[1] = this.pos.y;
    posAtt[2] = this.pos.z;

    const positions = this.particles.attributes.position.array;
    for (let i = 0; i < positions.length; i += 3) {
        positions[i] = this.pos.x;
        positions[i + 1] = this.pos.y;
        positions[i + 2] = this.pos.z;
    }

    this.particles.attributes.position.needsUpdate = true;
    this.accretion.x = this.accretion.x * 0;
    this.accretion.y = this.accretion.y * 0;
    this.accretion.z = this.accretion.z * 0;
    
    if (!this.firework) {
      this.velocity.x *= 0.95;
      this.velocity.y *= 0.95;
      this.velocity.z *= 0.95;
      this.lifespan -= 4;
      this.pMaterial.opacity -= 0.015;
      if (this.pMaterial.opacity <= 0) {
        this.reset();
      }
    }
    //   // console.log('this.particles   ', this.particles);
  }
  reset() {
    this.done = true;
    this.remove(this.particleX);
    this.particles = null;
    this.particleX = null;
    this.pos = null;
    this.accretion = null;
    this.pMaterial = null;
    this.texture = null;
  }
  update() {
    // this.updateSort();
    if (!this.end && this.particles) {
      this.animateParticle();
    }

    // this.applyForce(this.gravity);
  }
  show() {
    if (!this.end && this.particles) {
      this.add(this.particleX);
    }
  }
}
