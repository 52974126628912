import { createRouter, createWebHashHistory } from 'vue-router';
import WebAR from '../views/WebAR.vue';
import Home from '../views/Home.vue';
import Error from '../views/Error.vue';
import Exit from '../views/Exit.vue';
import Game from '../views/Game.vue';
// import Instructions from '../views/Instructions.vue';
import PermissionError from '../views/PermissionError.vue';
import BrandDetection from '../views/BrandDetection.vue';
import PlaneDetection from '../views/PlaneDetection.vue';
import Select from '../views/Select.vue';
import Play from '../views/Play.vue';
import Share from '../views/Share.vue';

import store from "../Store";

function saveGroup(to, from, next) {
  if (to.params.id) {
    localStorage.setItem('ttt_group', JSON.stringify(to.params))
    store.group = to.params;
  } else if (localStorage.getItem("ttt_group") != null) {
    store.group = JSON.parse(localStorage.getItem("ttt_group"));
  }
  if (store.state.group.id == "2") {
    require("../utils/Analytics");
  }else {
    window.webar_TTT = {
      init: function () {
      },
      tracking: {
        intervals: [1, 2, 3, 5, 10, 15, 30, 45, 60, 120],
        queue_time_events: function () {
        },
        track_action: function (name) {
          console.log(name)
        },
        track_gamestate: function (state) {
          console.log(state)
        }
      }
    }
  }
  next('/');
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props:true
  },
  {
    path: "/group/:id/:shaq?",
    name: "HomePage",
    component: Home,
    props: true,
    beforeEnter: saveGroup
  },
  {
    path: '/webar',
    name: 'WebAR',
    component: WebAR,
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: '',
        component: BrandDetection
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'start',
        component: PlaneDetection
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'select',
        component: Select
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'play',
        component: Play
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'share',
        component: Share
      },
      {
        path: 'exit',
        name: 'Exit',
        component: Exit,
      },
      {
        path: '/error',
        name: 'Error',
        component: Error,
      }
    ]
  },
  // {
  //   path: '/webar',
  //   name: 'WebAR',
  //   component: WebAR,
  // },

  {
    path: '/permission-error',
    name: 'PermissionError',
    component: PermissionError,
  },
  {
    path: '/game',
    name: 'Game',
    component: Game,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
