<template>
  <button @click="onClick" :type="type" class="button-ball">
    <!-- <img src="/assets/images/Icon/dots2.png" /> -->
    <span class="text-light">
      <slot name="header"></slot>
    </span>
    <br />
    <span class="text-black">
      <slot>Button</slot>
    </span>
  </button>
</template>

<script>
export default {};
</script>

<style lang="scss">
.button-ball {
  background-color: transparent;
  background-image: url("~@/assets/images/Icon/ball_button_no_text.png");
  background-size: 120px;
  border: none;
  color: $white;
  font-size: 24px;
  height: 120px;
  letter-spacing: -0.3px;
  line-height: 24px;
  text-transform: uppercase;
  width: 120px;

  .text-light {
    // font-family: MissionGothic-LightItalic;
    font-weight: 200;
    font-style: italic;
  }

  .text-black {
    // font-family: MissionGothic-BlackItalic;
    font-weight: bold;
    font-style: italic;
  }
}
</style>