require("aframe");
require('aframe-play-sound-on-event')
require("aframe-extras");
require("aframe-orbit-controls");
require("aframe-particle-system-component");

import FireworkShow from './lib/Fireworks/FireworkShow';

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import * as THREE from 'three';

// import * as THREE from "three";
import { physicsImageTargetComponent } from './utils/physicsImageTargetComponent'
// import './utils/WebRTCFix.js';
// import getPipelineModule from './lib/getPipelineModule';


// let XRExtras = null;
const AFRAME = window.AFRAME;
const createVue = () => {
  createApp(App)
    .use(router)
    .mount("#app");
  AFRAME.registerComponent('physics-image-target', physicsImageTargetComponent);
  AFRAME.registerComponent('render-setting', {
    init: function () {
      // console.log('render>>>>>>>>', this.el.renderer);
      this.el.renderer.outputEncoding = THREE.sRGBEncoding;
      this.el.renderer.shadowMap.enabled = true;
      this.el.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.el.renderer.gammaOutput = true;
      this.el.renderer.gammaFactor = 2.2;
      this.el.renderer.sortObjects = false;
    }
  });
  AFRAME.registerComponent('modify-materials', {
    init: function () {
      // Wait for model to load.
      // console.log('modify-materials   ');
      this.el.addEventListener('model-loaded', () => {
        // console.log('model-loaded   ');
        // Grab the mesh / scene.
        const obj = this.el.getObject3D('mesh');
        // console.log('model-loaded obj  ', obj);
        // Go over the submeshes and modify materials we want.
        obj.traverse(node => {
          // console.log('node.name   ', node.name);
          // node.material.transparent = true;
          // node.material.alphaTest = 0.5;
          // node.material.depthWrite = false;
          // if (node.name.indexOf('skeleton_#4') !== -1) {
          //   console.log('node.name   ', node);
          // }

          
          node.frustumCulled = false;
          if (node.material) {
            node.material.transparent = true
            // console.log('node.name   ', node);
            if (node.material.name === '_Arch_Glass_Basicb1' || node.material.name === 'm_tony_lens') {
              node.visible = false;
            }

          }
        });
      });
    }
  });
  AFRAME.registerComponent('modify-sound', {
    init: function () {
      // Wait for model to load.
      console.log('modify-sound   ');
      this.el.addEventListener('sound-loaded', () => {
        // console.log('sound-loaded   ', this.el);
        // console.log('sound-loaded  getPlayRate ', this.el.getAttribute('sound').getPlaybackRate);
        // console.log('sound-loaded   ', this.el.components.sound);
        // console.log('sound-loaded  Source ', this.el.components.sound.pool.children[0].context);
        let speed = this.el.getAttribute('sound').getPlaybackRate ? this.el.getAttribute('sound').getPlaybackRate : 1.0;
        var sound = null;
        var entity = document.querySelector('[sound]');
        for (let i = 0; i < entity.components.sound.pool.children.length; i++) {
          sound = this.el.components.sound.pool.children[i];
          console.log('SOUND>>>>>>>>>>>>', sound)
          sound.setPlaybackRate(speed)
        }
      });
    }
  });
  AFRAME.registerComponent('foo', {
    init:function(){
      const pos = this.el.getAttribute('position')
      this.fireworks = new FireworkShow(pos);
      let object = this.el.object3D;
      console.log('fireworks   ', this.fireworks);
      console.log('object   ', object);
      // this.el.setObject3D('mesh', fireworks);
      
      object.attach(this.fireworks)
      // this.el.sceneEl.object3D.add(this.fireworks);

      const startFireworks = () => {
        console.log('startFireworks   ', this);
        this.fireworks.startend(true);
      }
      const endFireworks = () => {
        this.fireworks.startend(false);
      }
      window.addEventListener('fireworks-start', startFireworks);
      window.addEventListener('fireworks-end', endFireworks);
      // this.el.setObject3D('mesh', this.fireworks);
    },
    tick: function() {
      // console.log('this.el    ', this.el.object3D.position);
      // this.el.object3D.rotation.x += Math.PI * 0.1 / 180;
      this.fireworks.update()
    }
  });

};

createVue();
const XR8 = null;
const appendScript = (src, attributes = {}) => {
  const scriptNode = document.createElement("script");
  for (const key in attributes) {
    scriptNode.setAttribute(key, attributes[key]);
  }
  scriptNode.setAttribute("src", src);
  document.body.appendChild(scriptNode);
  return scriptNode;
};

const initializeSDK = () => {
  console.log( "initializeSDK", process.env );
  appendScript(`${process.env.VUE_APP_EIGHTTHWALL_WEB_SRC}?appKey=${process.env.VUE_APP_EIGHTTHWALL_GEENEE_API_KEY}`, { async: "async" });
  appendScript(process.env.VUE_APP_EIGHTTHWALL_EXTRA_CDN);
};

const onLoad = () => {
  console.log('XR    ', (typeof XR8));
  if (typeof XR8 === "undefined") {
    console.log('set time out');
    return setTimeout(onLoad, 100);
  }
  window.XR8 = XR8;
  console.log('set onXRLoaded');
};

const runInitSequence = () => {
    console.log("runInitSequence");
    window.XRExtras ? onLoad() : window.addEventListener("xrextrasloaded", onLoad);
    setTimeout(initializeSDK, 0);

}

window.runInitSequence = runInitSequence();