<template>
  <div class="instructions">
    <div class="instructions__icon" v-if="!isIconHidden">
      <img :src="getURL(icon)" alt="play" />
    </div>
    <div class="instructions__text helper">
      <span class="helper__text" :class="kind"><slot>instructions</slot></span>
    </div>
    <div v-if="!isButtonHidden" class="instructions__button">
      <v-button-icon v-if="!isHidden" @click.prevent="onNextAction">
        <img src="@/assets/logo.png" alt="play" />
      </v-button-icon>
    </div>
  </div>
</template>

<script>
import ButtonIcon from "@/components/ButtonIcon";

export default {
  components: {
    "v-button-icon": ButtonIcon,
  },
  props: {
    kind: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
    },
    isButtonHidden: {
      type: Boolean,
      default: true,
    },
    isIconHidden: {
      type: Boolean,
      required: true,
      default: true,
    },
    isHidden: {
      type: Boolean,
      required: true,
      default: true,
    },
    onNext: {
      type: Function,
      default: undefined,
    },
  },
  methods: {
    getURL(image) {
      return require("@/assets/" + image);
    },
    onNextAction() {
      if (this.onNext) {
        this.onNext();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 0 26px;
  width: 100%;

  &__icon {
    margin-bottom: 10px;
    background-color: #838589;
    transform: translate(0px, 0px);
    border-radius: 50%;
    padding: 14px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-family: inherit;
    margin-bottom: 20px;
    padding: 3px;
    text-decoration: none;
  }

  &__button {
    width: 60px;
    height: 60px;
    margin-bottom: 80px;

    .button-icon {
      height: 100%;
      width: 100%;

      img {
        height: 70%;
        width: 70%;
      }
    }
  }
}
</style>