<template>
  <div class="download pagefull">
    <div class="download__overlay"></div>
    <div class="download__message">
      <div class="download__hint" v-if="isIOS">
        <p class="text">View your highlight in Downloads</p>
      </div>
      <div  class="download__gentext">
        <h2 class="header">Choose download to save<br />your highlight</h2>
        <img
          :src="require('@/assets/images/Icon/tap_download.png')"
          :srcset="require('@/assets/images/Icon/tap_download@2x.png') + ' 2x'"
          alt="Allow download"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { MobileVer } from "@/lib/helper";
export default {
  data: function () {
    return {
      isIOS: true
    };
  },
  mounted() {
    if(MobileVer() === 'iOS') {
      this.isIOS = true;
    } else {
      this.isIOS = false;
    }
  },
  methods: {
    getURL(image) {
      return require("@/assets/" + image);
    },
    onNextAction() {
      if (this.onNext) {
        this.onNext();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  &__overlay {
    background-color: $black20;
    backdrop-filter: blur(5px);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 4000;
    display: block;
  }

  &__message {
    color: $white;
    display: none;
    margin: 0;
    width: 100%;
    z-index: 5000;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    left: 0;
    padding: 0 26px;
    // > * {
    //   margin-top: 60px;
    // }
  }
  &__gentext{
    margin-top: 5%;
    text-align: center;
    .header {
      margin-bottom: 1.3rem;
    }
  }
  &__hint {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    .icon {
      align-self: flex-start;
      padding-top: 30px;
    }
    .text {
      // padding: 30px 10px 0 10px;
      background: rgba(0, 0, 0, 0.33);
      border-radius: 6px;
      -webkit-backdrop-filter: blur(23px);
      backdrop-filter: blur(23px);
      padding: 5px 10px;
    }
  }
}
</style>