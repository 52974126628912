import store from "../Store";

/* eslint-disable */
(function (w, d, s, l, i) {
  w[l] = w[l] || []; w[l].push({
    'gtm.start':
      new Date().getTime(), event: 'gtm.js'
  }); var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-5GQ8W5D');

function zeroFill(number, width) {
  width -= number.toString().length;
  if (width > 0)
    return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
  return number + "";
}
window.webar_TTT = {
  init: function () {
    if (store.state.group.id == "2") {
      window.webar_TTT.tracking.queue_time_events();
    }
  },
  tracking: {
    intervals: [1, 2, 3, 5, 10, 15, 30, 45, 60, 120],
    queue_time_events: function () {
      window.webar_TTT.tracking.intervals.forEach(function (interval) {
        setTimeout(function () {
          dataLayer.push({ event: "ni_event", category: 'ttt_ar_metrics', action: 'page_time_' + zeroFill(interval, 3), label: "ttt_ar", nonInteraction: true });
        }, interval * 1000);
      })
    },
    track_action: function (name) {
      if (store.state.group.id == "2") {
        dataLayer.push({ event: "event", category: 'ttt_ar', action: 'action', label: name });
      }
    },
    track_gamestate: function (state) {
      if (store.state.group.id == "2") {
        dataLayer.push({ event: "ni_event", category: 'ttt_ar_metrics', action: 'game_state', label: "state", nonInteraction: true });
      }
    }
  }
}
document.addEventListener("DOMContentLoaded", window.webar_TTT.init);
/* eslint-enable */