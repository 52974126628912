<template>
  <v-link class="link-exit" :onClick="exit"
    >Exit to Main site</v-link
  >
</template>

<script>
import Link from "@/components/Link";
import { externalLink } from "@/lib/helper";

export default {
  components: {
    "v-link": Link,
  },
  methods: {
    exit() {
      window.webar_TTT.tracking.track_action('exit');
      window.location.href = externalLink();
    },
  },
};
</script>

<style lang="scss">
.link-exit {
  color: $white;
  margin-bottom: 20px;
  text-decoration: underline;
  text-transform: uppercase;

  @include breakpoint(640px, "from") {
    font-size: 18px;
  }
}
</style>