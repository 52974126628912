<template>
  <div class="game pagefull">
    <div class="game__step game__step1" v-if="step === 0">
      <v-instructions
        :isHidden="isHidden"
        :isIconHidden="false"
        icon="logo.png"
      >
        Move your device to find a box
      </v-instructions>
    </div>
    <div class="game__step game__step2" v-if="step === 1">
      <v-instructions :isHidden="isHidden">
        Move your device to find an AR surface
      </v-instructions>
    </div>
    <div class="game__step game__step3" v-if="step === 2">
      <v-carousel :cards="tonycards" :onSelect="selected"></v-carousel>
    </div>
    <div class="game__step game__step4" v-if="step === 3">
      <v-carousel :cards="shaqcards" :onSelect="selected"></v-carousel>
    </div>
    <div class="game__step game__step4" v-if="step === 4">
      <v-finish
        title="Nice work Tiger!"
        subtitle="Save your epic pic and share it with the world using #TonyVsShaq"
        player="Shaq's"
        :onClickReplay="consoleClick"
        :onClickTurn="consoleClick"
        image="images/Photo/tony_finish.png"
        background="images/Photo/finish_bg.png"
      />
    </div>
    <div class="game__step game__step5" v-if="step === 5">
      <v-finish
        title="Nice shot, Tiger!"
        message="Save your epic pic and share it with the world using #TonyVsShaq"
        player="Tony's"
        :onClickReplay="consoleClick"
        :onClickTurn="consoleClick"
        image="images/Photo/shaq_finish.png"
        background="images/Photo/finish_bg.png"
      />
    </div>
    <div class="game__step game__step6" v-if="step === 6">
      <v-download />
    </div>
    <div class="game__buttons">
      <v-button :onClick="showButton">Show</v-button>
      <v-button :onClick="prevSection">Prev</v-button>
      <v-button :onClick="nextSection">Next</v-button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Instructions from "@/components/Instructions";
import Carousel from "@/components/Carousel";
import FinishGame from "@/components/FinishGame";
import DownloadOverlay from "@/components/DownloadOverlay";

export default {
  components: {
    "v-button": Button,
    "v-instructions": Instructions,
    "v-carousel": Carousel,
    "v-finish": FinishGame,
    "v-download": DownloadOverlay,
  },
  data() {
    return {
      step: 0,
      isHidden: true,
      tonycards: [
        {
          id: 1,
          name: "Flying Tiger",
          title: "Choose Tony's Jam",
          image: "images/Photo/01_Tony_Carousel.png",
          background: "images/Photo/bg_tony.png",
          model: "./assets/models/tony/dunk1.gltf",
          cMainId: 0,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
        },
        {
          id: 2,
          name: "Tail-y Oop",
          title: "Choose Tony's Jam",
          image: "images/Photo/02_Tony_Carousel.png",
          background: "images/Photo/bg_tony.png",
          model: "./assets/models/tony/dunk2.gltf",
          cMainId: 1,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
        },
        {
          id: 3,
          name: "Over the Mountain",
          title: "Choose Tony's Jam",
          image: "images/Photo/03_Tony_Carousel.png",
          background: "images/Photo/bg_tony.png",
          model: "./assets/models/tony/dunk3.gltf",
          cMainId: 2,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          tony: null,
        },
      ],
      shaqcards: [
        {
          id: 1,
          name: "The Big Put Back",
          title: "Choose Shaq's Jam",
          image: "images/Photo/01_Shaq_Carousel.png",
          background: "images/Photo/bg_shaq.png",
          model: "./assets/models/tony/dunk1.gltf",
          cMainId: 0,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
        },
        {
          id: 2,
          name: "Signature Shaq",
          title: "Choose Shaq's Jam",
          image: "images/Photo/02_Shaq_Carousel.png",
          background: "images/Photo/bg_shaq.png",
          model: "./assets/models/tony/dunk2.gltf",
          cMainId: 1,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
        },
        {
          id: 3,
          name: "Backboard Beware",
          title: "Choose Shaq's Jam",
          image: "images/Photo/03_Shaq_Carousel.png",
          background: "images/Photo/bg_shaq.png",
          model: "./assets/models/tony/dunk3.gltf",
          cMainId: 2,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
        },
      ],
    };
  },
  methods: {
    nextSection() {
      this.step++;
      this.isHidden = true;
    },
    prevSection() {
      if (this.step > 0) {
        this.step--;
        this.isHidden = true;
      }
    },
    showButton() {
      this.isHidden = false;
    },
    selected(card) {
      alert(card.name);
    },
    consoleClick() {
      console.log("Button clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.game {
  background-color: $black;
  display: flex;
  position: relative;

  &__step {
    width: 100%;
  }

  &__step1,
  &__step2 {
    align-self: flex-end;
    justify-self: flex-end;
  }

  &__buttons {
    bottom: 10px;
    display: flex;
    position: absolute;
    right: 10px;
    z-index: 9999;

    button {
      width: 70px;
    }
  }
}
</style>