<template>
  <transition
    appear
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="Play">
      <v-header />
      <div class="countdown" id="three">
        <p class="copy">GET READY!</p>
        <img
          class="centerme number"
          :src="require('@/assets/images/UI/3.svg')"
          alt="1"
        />
       <!-- <img
          class="centerme background"
          :src="require('@/assets/images/UI/Countdown.png')"
          alt="Background"
        />-->
      </div>
      <div class="countdown" id="two">
        <p class="copy">GET HYPED!</p>
        <img
          class="centerme number"
          :src="require('@/assets/images/UI/2.svg')"
          alt="1"
        />
       <!-- <img
          class="centerme background"
          :src="require('@/assets/images/UI/Countdown.png')"
          alt="Background"
        />-->
      </div>
      <div class="countdown" id="one">
        <p class="copy">START RECORDING!</p>
        <img
          class="centerme number"
          :src="require('@/assets/images/UI/1.svg')"
          alt="1"
        />
       <!-- <img
          class="centerme background"
          :src="require('@/assets/images/UI/Countdown.png')"
          alt="Background"
        />-->
      </div>
      <v-footer class="footer_div" />
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.Brand {
  position: absolute;
  bottom: -90px;
  align-self: flex-end;
  justify-self: flex-end;
}
.countdown {
  width: 100%;
  font-size: 10rem;
  position: relative;
  top: 31%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}
.centerme {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.copy {
  position: absolute;
  // font-family: MissionGothic-BlackItalic;
  font-weight: bold;
  font-style: italic;
  /* height: 100%; */
  margin: auto;
  color: white;
  font-size: 2.8rem;
  width: 100%;
  text-align: center;
  /* background-color: blue; */
  z-index: 30;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px #f1682f;
}
.number {
  z-index: 10;
}
.background {
  z-index: 0;
}
</style>
<script>
// @ is an alias to /src
import { gsap } from "gsap";

import store from '../Store'
import router from "../router/index";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
// import { Back, Power2, Power3 } from "gsap/EasePack";
// import { CSSPlugin } from "gsap/CSSPlugin";
// import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer'

// physics-image-target="name: tonytiger"
export default {
  name: "Play",
  components: {
    "v-footer": Footer,
    "v-header": Header,
  },
  data: function() {
    return {
      init: gsap.timeline(),
      nThree: null,
      nTwo: null,
      nOne: null,
      tony: null,
      animationList: ["All Animations", "a"],
      idx: 0,
      endEvent: null,
      soundEvent: null,
      state: store.state,
      soundItem: null,
      fireworks: null,
      dunksound: null,
      delayTime: 7,
      countSnd: null,
      countSndEnd: null,
      talkSnd: null,
      postSnd: false
    };
  },
  mounted() {
    console.log("MultiSelect mounted!");
    window.webar_TTT.tracking.track_gamestate('dunking');
    this.tony = document.getElementById("model0");
    this.soundItem = document.getElementById("side2");
    this.fireworks = document.getElementById("fireworks");
    this.dunksound = document.getElementById("ground");
    this.talkSnd = document.getElementById("talk");
    this.postSnd = false;
    //count animation
    this.countSnd = document.getElementById("countNum");
    this.countSndEnd = document.getElementById("countNumEnd");
    
    console.log('this.countSnd', this.countSnd);
    this.resetAudioAnim();
    

    gsap.set('.tempDisable', {autoAlpha:.5, pointerEvents: "none"})
    gsap.set('.switch', {autoAlpha:.5, pointerEvents: "none"})
    this.setAnimation();
    const self = this;
    this.playSound('talkSnd', 'play');
    if(this.endEvent == null){
      
      this.soundEvent = this.talkSnd.addEventListener('sound-ended', self.smackTalk.bind(self))
      this.endEvent = this.tony.addEventListener('animation-finished', self.finishAnimation.bind(self))
    }

  },
  unmounted() {
    this.talkSnd.removeEventListener('sound-ended', this.smackTalk.bind(this))
    this.tony.removeEventListener('animation-finished', this.finishAnimation.bind(this))
    this.resetAudioAnim();
  },
  updated() {
    console.log("updated!");
  },
  methods: {
    smackTalk: function (e) {
        console.log('SOUND ENDED', e);
        if(!this.postSnd){
          this.talkSnd.setAttribute("sound", {src: `url(./assets/sound/talk/${this.state.talk_active.post})`, autoplay:false, volume: 0});
          this.countdown();
        } else {
          console.log('SECOND SOUND DONE')
        }
        
      },
    finishAnimation: function () {
      console.log('FINISHED ANIMATION');
      this.postSnd = true;
      this.playSound('talkSnd', 'play', 1);
      window.dispatchEvent(new CustomEvent("mediarecorder-endcapture"));
      this.countSnd.setAttribute("sound", {autoplay:false, volume: 0});
      this.countSndEnd.setAttribute("sound", {autoplay:false, volume: 0});
      setTimeout(() => {
        console.log('SHOW IS : OFF');
        window.dispatchEvent(new CustomEvent("fireworks-end", {detail: "Fireworks Stop"}));
        this.resetAudioAnim();
        router.push('share');
      }, 3000)
    },
    resetAudioAnim: function(){
      this.tony.removeAttribute("gltf-model");
      // this.tony.setAttribute("gltf-model", this.state.dunk.model);
      this.tony.setAttribute("animation-mixer", {timeScale: 0});
      this.dunksound.setAttribute("sound", {src: `''`, autoplay:false, volume: 0});
      this.soundItem.setAttribute("sound", {src: `''`, autoplay:false, volume: 0});
      this.countSnd.setAttribute("sound", {autoplay:false, volume: 0});
      this.countSndEnd.setAttribute("sound", {autoplay:false, volume: 0});
    },
    setAnimation: function() {
      
      this.tony.removeAttribute("gltf-model");
      this.tony.setAttribute("gltf-model", this.state.dunk.model);
      this.tony.setAttribute("animation-mixer", {timeScale: 0});
      this.dunksound.setAttribute("sound", {src: `url(${this.state.dunk.sound})`, autoplay:false, volume: 0});
      this.soundItem.setAttribute("sound", {src: `url(./assets/sound/crowd_cheer.m4a)`, autoplay:false, volume: 0});
      this.talkSnd.setAttribute("sound", {src: `url(./assets/sound/talk/${this.state.talk_active.pre})`, autoplay:false, volume: 0});
      this.delayTime = this.state.dunk.fireworks;
      console.log('this.state.dunk.sound   ', this.state.dunk.sound)
    },
    playAnimation: function(name, speed, id=0) {
      console.log('SLOWMO  ', this.state.slowmo);
      this[name].setAttribute("animation-mixer", {
        clip: this.animationList[id],
        crossFadeDuration: 0.4,
        timeScale: this.state.slowmo,
        clampWhenFinished: true,
        loop: "once",
      });

    },
    playSound: function(name, action, speed = null){
      this[name].setAttribute("sound", {
        getPlaybackRate: speed ? speed : this.state.slowmo,
        volume: 1
      });

      if(action === "play"){
        this[name].components.sound.playSound();
      }else if(action === "stop") {
        this[name].components.sound.playStop();
      } else if(action === "pause") {
        this[name].components.sound.pauseStop();
      }
    },
    playFireworks: function(){
      this.playSound("fireworks", "play");
      window.dispatchEvent(new CustomEvent("fireworks-start", {detail: "Fireworks Play"}));
    },
    countdown: function(){
      let copy = this.nThree.getElementsByClassName("copy");
      let number = this.nThree.getElementsByClassName("number");
      // let background = this.nThree.getElementsByClassName("background");

      gsap.set([".copy", ".number", ".background"], { autoAlpha: 0 });

      let self = this;
      let tl = gsap.timeline({onComplete: function(){ 
      // what do I return here to be able to chain a promise 
        // gsap.to('.footer', {duration: 0.2, autoAlpha: 1})
        self.playSound('countSndEnd', 'play');

        self.countSndEnd.play()
        gsap.to('.tempDisable', {duration: 0.5, autoAlpha:1, pointerEvents: "auto"})
        gsap.delayedCall(5, self.playAnimation, ["tony", .99]);
        gsap.delayedCall(5, self.playSound, ["dunksound", "play"]);
        gsap.delayedCall(self.delayTime, self.playSound, ["soundItem", "play"]);
        gsap.delayedCall(self.delayTime, self.playFireworks);
        gsap.to('.switch', {duration: 0.2, autoAlpha:1, pointerEvents: "auto", delay: 3.2})
      }});

      this.recorder = document.getElementById("recorder");
      gsap.set('#recorder', {zIndex: 5000});
      gsap.set('#flashElement', {zIndex: 5001});
      gsap.set('#previewContainer', {zIndex: 5002});
      gsap.to(this.recorder, { duration: 0.5, autoAlpha: 1 });

      //add 3 tweens that will play in direct succession.
      tl.to(this.nThree, { duration: 0.5, autoAlpha: 1});

      // tl.to(background, { duration: 0.5, autoAlpha: 1 });
      tl.set(number, { scale: 0.5 });
      tl.to(number, { duration: 0.5, autoAlpha: 1, scale: 1.2});
      tl.add(function(){self.playSound('countSnd', 'play');})
      tl.to(number, { duration: 1, autoAlpha: 0, scale: 1 });
      tl.to(copy, { duration: 0.5, autoAlpha: 1 }, "<");
      tl.to(this.nThree, { duration: 0.5, autoAlpha: 0, delay: 1 });

      copy = this.nTwo.getElementsByClassName("copy");
      number = this.nTwo.getElementsByClassName("number");
      // background = this.nTwo.getElementsByClassName("background");

      tl.to(this.nTwo, { duration: 0.5, autoAlpha: 1 }, "<-.1");
      // tl.to(background, { duration: 0.5, autoAlpha: 1 }, "<");
      tl.set(number, { scale: 0.5 });
      tl.to(number, { duration: 0.5, autoAlpha: 1, scale: 1.2});
      tl.add(function(){self.playSound('countSnd', 'play');})
      tl.to(number, { duration: 1, autoAlpha: 0, scale: 1 });
      tl.to(copy, { duration: 0.5, autoAlpha: 1 }, "<");
      tl.to(this.nTwo, { duration: 0.5, autoAlpha: 0, delay: 1 });

      copy = this.nOne.getElementsByClassName("copy");
      number = this.nOne.getElementsByClassName("number");
      // background = this.nOne.getElementsByClassName("background");

      tl.to(this.nOne, { duration: 0.5, autoAlpha: 1 }, "<-.1");
      // tl.to(background, { duration: 0.5, autoAlpha: 1 }, "<");
      tl.set(number, { scale: 0.5 });
      tl.to(number, { duration: 0.5, autoAlpha: 1, scale: 1.2});
      tl.add(function(){self.playSound('countSnd', 'play');})
      tl.to(number, { duration: 1, autoAlpha: 0, scale: 1 });
      tl.to(copy, { duration: 0.5, autoAlpha: 1 }, "<");
      tl.to(this.nOne, {
        duration: 0.5,
        autoAlpha: 0,
        delay: 1
      });
    },
    beforeEnter(el) {
      // gsap.set(el, { opacity: 0 });
      console.log("beforeEnter el  ", el);
    },
    transition: function(name, speed) {
      this[name].setAttribute("animation-mixer", {
        clip: this.animationList[this.idx],
        crossFadeDuration: 0.4,
        timeScale: speed,
        loop: 0,
        // recorder: null
      });
    },
    enter(el, done) {
      window.renderCapture = {video: null, image: null};
      // this.countdown();
      this.nThree = document.getElementById("three");
      this.nTwo = document.getElementById("two");
      this.nOne = document.getElementById("one");

      this.nOne = document.getElementById("one");

      // Set start
      gsap.set([this.nThree, this.nTwo, this.nOne], { autoAlpha: 0 });
      done();
    },
    leave(el, done) {
      // gsap.to(el, 1, { opacity:0, onComplete:done });
      console.log("leave el  ", el);
      done();
    },
    next: function() {
      router.push("start");
    },
  },
};
</script>
