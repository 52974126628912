<template>
  <div class="home pagefull">
    <div class="home__bg">
      <img
        v-if="isMobile()"
        :src="require('@/assets/images/Photo/home_dots.png')"
        :srcset="require('@/assets/images/Photo/home_dots@2x.png') + ' 2x'"
        alt=""
      />
      <img
        v-if="!isMobile()"
        :src="require('@/assets/images/Photo/home_dots_large.png')"
        :srcset="
          require('@/assets/images/Photo/home_dots_large@2x.png') + ' 2x'
        "
        alt=""
      />
    </div>
    <div class="home__overlay" :class="{ show: showMessage }"></div>
    <div class="home__message" :class="{ show: showMessage }">
      <h2>We need your permission</h2>
      <div class="home__message__info">
        <img
          :src="require('@/assets/images/Icon/Camera.png')"
          :srcset="require('@/assets/images/Icon/Camera@2x.png') + ' 2x'"
          alt="Access Camera"
          width="32"
          height="32"
        />
        <p>Grant access to your phone camera</p>
        <img
          :src="require('@/assets/images/Icon/AR.png')"
          :srcset="require('@/assets/images/Icon/AR@2x.png') + ' 2x'"
          alt="Access AR"
          width="32"
          height="32"
        />
        <p>Grant access to motion & orientation</p>
      </div>
      <img
        :src="require('@/assets/images/Icon/TapAllow.png')"
        :srcset="require('@/assets/images/Icon/TapAllow@2x.png') + ' 2x'"
        alt="Camera"
        width="128"
        height="64"
      />
    </div>
    <div class="home__logo">
      <img
        v-if="isMobile()"
        :src="require('@/assets/images/Photo/tony_shaq.png')"
        :srcset="require('@/assets/images/Photo/tony_shaq@2x.png') + ' 2x'"
        alt="Tony and Shaq"
      />
      <img
        v-if="isMobile()"
        :src="require('@/assets/images/Photo/tony_shaq_logo.png')"
        :srcset="require('@/assets/images/Photo/tony_shaq_logo@2x.png') + ' 2x'"
        alt="Tony and Shaq Logo"
      />
      <img
        v-if="!isMobile()"
        :src="require('@/assets/images/Photo/tony_shaq_large.png')"
        :srcset="
          require('@/assets/images/Photo/tony_shaq_large@2x.png') + ' 2x'
        "
        alt="Tony and Shaq"
      />
      <img
        v-if="!isMobile()"
        :src="require('@/assets/images/Photo/tony_shaq_logo_large.png')"
        :srcset="
          require('@/assets/images/Photo/tony_shaq_logo_large@2x.png') + ' 2x'
        "
        alt="Tony and Shaq Logo"
      />
    </div>
    <v-button :onClick="showButton" kind="button__quaternary">
      <img
        v-if="isMobile()"
        :src="require('@/assets/images/CTA/home_button.png')"
        :srcset="require('@/assets/images/CTA/home_button@2x.png') + ' 2x'"
        alt="Let's go"
      />
      <img
        v-if="!isMobile()"
        :src="require('@/assets/images/CTA/home_button_large.png')"
        :srcset="
          require('@/assets/images/CTA/home_button_large@2x.png') + ' 2x'
        "
        alt="Let's go"
      />
    </v-button>
    <img
      v-if="isMobile()"
      :src="require('@/assets/images/Icon/powered_by.png')"
      :srcset="require('@/assets/images/Icon/powered_by@2x.png') + ' 2x'"
      alt="Powered by 8th WALL"
    />
    <img
      v-if="!isMobile()"
      :src="require('@/assets/images/Icon/powered_by_large.png')"
      :srcset="require('@/assets/images/Icon/powered_by_large@2x.png') + ' 2x'"
      alt="Powered by 8th WALL"
    />
    <v-link-exit />
  </div>
</template>
<script>
import Button from "@/components/Button";
import LinkExit from "@/components/LinkExit";
import Permissions from "@/lib/Permissions";
import store from "../Store";
export default {
  name: "Home",
  components: {
    "v-button": Button,
    "v-link-exit": LinkExit,
  },
  mounted() {
    console.log('route.params  ', store.state.group);
    window.permissionObj = Permissions;
    if (store.state.group === -1 && localStorage.getItem("ttt_group") != null) {
      store.group = JSON.parse(localStorage.getItem("ttt_group"));
      console.log('route.params  ', store.state.group);
    }
    // window.webar_TTT.tracking.track_gamestate('home');
  },
  methods: {
    showButton() {
      window.webar_TTT.tracking.track_gamestate('camera_permission');
      this.showMessage = true;
      Permissions.permissions();
    },
    isMobile() {
      return screen.width <= 760 ? true : false;
    }
  },
  data() {
    return {
      showMessage: false,
    };
  },
};
</script>


<style lang="scss" scoped>
.home {
  background-color: $black;
  background-size: cover;
  background-image: url("~@/assets/images/Photo/home_bg.png");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  grid-template-rows: repeat(1, 1fr);
  align-items: center;
  justify-items: center;
  justify-content: flex-end;
  // padding: 0 26px;
  position: relative;

  @media only screen and (min-resolution: 2dppx) {
    background-image: url("~@/assets/images/Photo/home_bg@2x.png");
  }

  > * {
    margin-bottom: 30px;
    z-index: 5;
  }

  &.show {
  }

  &__bg {
    bottom: -3px;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 2;

    img {
      width: 100%;
    }
  }

  &__logo {
    height: 70%;
    position: relative;
    width: 100%;

    img {
      bottom: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);

      &:first-child {
        top: 0;
        width: 100%;
      }

      &:last-child {
        @include breakpoint(640px, "from") {
          width: 63%;
        }
      }
    }
  }

  &__overlay {
    background-color: $black20;
    backdrop-filter: blur(5px);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  &__message {
    color: $white;
    display: none;
    margin: 0;
    width: 100%;
    z-index: 10;

    &.show {
      align-items: center;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50px;
      left: 0;
      padding: 0 26px;

      > * {
        margin-bottom: 10px;
      }
    }

    &__info {
      align-items: center;
      display: flex;
      justify-content: center;

      > * {
        margin-left: 5px;

        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}
</style>