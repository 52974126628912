<template>
  <div>
    <v-instructions class="findbrand" :is-hidden="true" :isIconHidden="true">
      Move your device to <br />find a Frosted Flakes<sup>®</sup> box
    </v-instructions>
    <img
      class="centerme detect"
      :src="require('@/assets/images/Icon/detect.png')"
      alt="Border"
    />
    <img
      class="centerme check"
      :src="require('@/assets/images/Icon/Checkmark.png')"
      alt="Check"
      @click.prevent="next"
    />
  </div>
</template>
<style lang="scss" scoped>
.Brand {
  position: absolute;
  bottom: -90px;
  align-self: flex-end;
  justify-self: flex-end;
}
.findbrand {
  position: absolute;
  bottom: 0px;
  align-self: flex-end;
  justify-self: flex-end;
}
.centerme {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
#loadBackground {
  display: none;
}
.check {
  opacity: 0;
}
</style>
<script>

import { gsap } from "gsap";
import router from "../router/index";
// @ is an alias to /src
import Instructions from "@/components/Instructions";

// physics-image-target="name: tonytiger"
export default {
  name: "Box",
  components: {
    "v-instructions": Instructions,
  },
  data: function() {
    return {
      shouldShow3dView: true,
      scene: document.getElementById("scene"),
      hideButton: true,
      foundEvent: null,
      lostEvent: null,
      // tonyModel: require('./assets/models/tony_ball_dunk1.glb')
    };
  },
  mounted() {
    console.log("mounted!");
    this.scene = document.getElementById("scene");
    window.webar_TTT.tracking.track_gamestate('scan_box');
    console.log("this.scene  ", this.scene);
    gsap.set(".check", {autoAlpha:0, scale: .5})
    gsap.set(".detect", {autoAlpha:0, scale: 2})

    let t2 = gsap.timeline();
    t2.to(".detect", { delay: 3, duration: .2, scale: .90, y:-30, autoAlpha: 1, ease:"back.out(1.7)"  });


    this.foundEvent = (e) => {
      console.log("image was found", e, this);
      console.log("GSAP", gsap);
      let tl = gsap.timeline();
      window.webar_TTT.tracking.track_action('scan_box_found');
      //add 3 tweens that will play in direct succession.
      tl.to(".check", { duration: 0.5, autoAlpha: 1, scale:2 });
      tl.to(".check", { duration: 1, scale:1, onComplete: function(){
        gsap.delayedCall(1, function(){
          router.push("/webar/start");
        })
      }
      });
      // this.hideButton = false;
    };

    this.scene.addEventListener("xrimagefound", this.foundEvent);
    // this.scene.addEventListener('xrimageupdated', showImage)
    this.lostEvent = () => {
      console.log("image was LOST");
      // this.hideButton = true;
    };
    this.scene.addEventListener("xrimagelost", this.lostEvent);
  },
  updated() {
    console.log("updated!");
  },
  unmounted() {
    console.log("unmount!", this);
    this.scene.removeEventListener("xrimagefound", this.foundEvent);
    this.scene.removeEventListener("xrimagelost", this.lostEvent);
  },
  methods: {
    next: () => {
      console.log("NEXT>>>>>.");
      router.push("/webar/start");
    }
  },
};
</script>
