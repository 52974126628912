<template>
  <transition
    appear
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="Plane">
      <div class="cover" :onClick="setStage" ref="cover" />
      <v-instructions
        class="tap activate"
        :isIconHidden="false"
        icon="images/Icon/Tap_and_drag.png"
        :is-hidden="hideButton"
        :onClick="setStage"
        id="tap"
        ref="pop"
      >
        Find a Surface
        <br />Tap to Place
      </v-instructions>
      <div class="tap letsgo" id="letsdunk" :onClick="next">
        <v-button-ball :onClick="onClickTurn" class="ball">
          <template v-slot:header>Let's</template>
          Dunk
        </v-button-ball>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.Brand {
  position: absolute;
  bottom: -90px;
  align-self: flex-end;
  justify-self: flex-end;
}
.tap {
  position: absolute;
  bottom: 0px;
  align-self: flex-end;
  justify-self: flex-end;
  pointer-events: auto;
}
.letsgo {
  text-align: center;
  width: 100%;
  bottom: 0px;
  pointer-events: auto;
  padding: 0 0 2rem 0;
  filter: drop-shadow(0 10px 0.75rem rgba(0, 0, 0, 0.53));
}
.cover {
  width: 100%;
  height: 100%;
  pointer-events: auto;
}
.activate {
  pointer-events: auto;
}
#countdown {
  position: absolute;
  text-align: center;
  top: 85px;
  width: 100%;
  font-size: 10rem;

  z-index: 2000;
}
</style>
<script>
// @ is an alias to /src
import { gsap } from "gsap";

import * as THREE from "three";
import router from "../router/index";
import { animateModel, modelFix, changeImage } from "@/lib/helper";
import Instructions from "@/components/Instructions";
import ButtonBall from "@/components/ButtonBall";

// Images
// const tex1 = new THREE.TextureLoader().load('./assets/images/CourtSide1/courtside1.jpg');
// tex1.flipY = false;
// const tex2 = new THREE.TextureLoader().load('./assets/images/CourtSide1/courtside2.jpg');
// tex2.flipY = false;
// const tex3 = new THREE.TextureLoader().load('./assets/images/CourtSide1/courtside3.jpg');
const tex4 = new THREE.TextureLoader().load(
  "./assets/images/CourtSide1/courtside4.jpg"
);
tex4.flipY = false;
const tex5 = new THREE.TextureLoader().load(
  "./assets/images/CourtSide1/courtside5.jpg"
);
tex5.flipY = false;
// const tex6 = new THREE.TextureLoader().load('./assets/images/CourtSide1/courtside6.jpg');
// const tex7 = new THREE.TextureLoader().load('./assets/images/CourtSide1/courtside7.jpg');
const tex8 = new THREE.TextureLoader().load(
  "./assets/images/CourtSide1/courtside8.jpg"
);
tex8.flipY = false;
// import { Back, Power2, Power3 } from "gsap/EasePack";
// import { CSSPlugin } from "gsap/CSSPlugin";
// import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer'

// physics-image-target="name: tonytiger"
export default {
  name: "Plane",
  components: {
    "v-instructions": Instructions,
    "v-button-ball": ButtonBall,
  },
  data: function() {
    return {
      instruct: new gsap.timeline({
        paused: false,
      }),
      shouldShow3dView: true,
      scene: document.getElementById("scene"),
      camera: document.getElementById("camera"),
      ground: document.getElementById("ground"),
      floor: document.getElementById("model2"),
      stage: document.getElementById("model"),
      tap: document.getElementById("tap"),
      letsdunk: document.getElementById("letsdunk"),
      model1: document.getElementById("model0"),
      court: document.getElementById("model1"),
      side1: document.getElementById("side1"),
      side2: document.getElementById("side2"),
      side3: document.getElementById("side3"),
      side4: document.getElementById("side4"),
      stageInit: false,
      timeRecenter: null,
      courtSnd:null
      // tonyModel: require('./assets/models/tony_ball_dunk1.glb')
    };
  },
  mounted() {
    window.webar_TTT.tracking.track_gamestate('select_plane');

    this.tap = document.getElementById("tap");
    this.tapIcon = this.tap.getElementsByClassName("instructions__icon");
    this.letsdunk = document.getElementById("letsdunk");
    this.courtSnd = document.getElementById("courtSnd");

    this.side1 = document.getElementById("side1");
    this.side2 = document.getElementById("side2");
    // this.side3 = document.getElementById("side3");
    // this.side4 = document.getElementById("side4");
    this.model1 = document.getElementById("model0");
    this.court = document.getElementById("model1");
    console.log("mounted!>>>>>>>>>");
    console.log(
      "mounted!>>>poppoppoppop>>>>>>",
      this.model1.getObject3D("mesh")
    );
    // instructions__icon

    let tl = gsap.timeline();
    gsap.set([this.tap, this.$refs.cover], { autoAlpha: 0 });
    gsap.set(this.letsdunk, { autoAlpha: 0, y: -200 });
    modelFix(this.model1);
    modelFix(this.court);

    // modelFix(this.side1)
    // modelFix(this.side2)
    // modelFix(this.side3)
    // modelFix(this.side4)

    animateModel(this.model1, gsap, "set", { opacity: 0 });
    animateModel(this.court, gsap, "set", { opacity: 0 });

    // animateModel(this.side1, gsap, "set", {opacity: 0})
    // animateModel(this.side2, gsap, "set", {opacity: 0})
    // animateModel(this.side3, gsap, "set", {opacity: 0})
    // animateModel(this.side4, gsap, "set", {opacity: 0})
    this.model1.setAttribute("visible", true);
    this.court.setAttribute("visible", true);

    animateModel(this.court, gsap, "to", { opacity: 0.5, duration: 1 });
    animateModel(this.model1, gsap, "to", { opacity: 0.5, duration: 1 });

    //add 3 tweens that will play in direct succession.
    tl.to(this.tap, { delay: 2, duration: 1, autoAlpha: 1 });
    tl.to(this.$refs.cover, { duration: 1, autoAlpha: 1 }, "<");
    this.timeRecenter = setInterval(this.reCenter, 1000);
    this.scene = document.getElementById("scene");
  },
  updated() {
    console.log("updated!");
  },
  methods: {
    beforeEnter(el) {
      // gsap.set(el, { opacity: 0 });
      console.log("beforeEnter el  ", el);
    },
    enter(el, done) {
      // gsap.to(el, 1, { opacity:1, onComplete:done });
      console.log("enter el  ", el);
      done();
    },
    leave(el, done) {
      // gsap.to(el, 1, { opacity:0, onComplete:done });
      console.log("leave el  ", el);
      done();
    },
    courtsideAnim: function() {
      console.log("courtsideAnim Start");
      let tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
      let delayTime = 1;
      changeImage(this.side1, tl, "to", { opacity: 0, duration: 1 }, ">");
      changeImage(this.side1, tl, "set", { map: tex5 }, ">");
      changeImage(this.side1, tl, "to", { opacity: 1, duration: 1 }, ">");
      changeImage(
        this.side1,
        tl,
        "to",
        { opacity: 0, duration: 0.5, delay: delayTime },
        ">"
      );
      changeImage(this.side1, tl, "set", { map: tex4 }, ">");
      changeImage(this.side1, tl, "to", { opacity: 1, duration: 1 }, ">");
      changeImage(
        this.side1,
        tl,
        "to",
        { opacity: 0, duration: 0.5, delay: delayTime },
        ">"
      );
      changeImage(this.side1, tl, "set", { map: tex8 }, ">");
      changeImage(this.side1, tl, "to", { opacity: 1, duration: 1 }, ">");
      changeImage(this.side1, tl, "to", { opacity: 0, duration: 0.5 }, ">");

      let t2 = gsap.timeline({ repeat: -1, repeatDelay: 1 });
      changeImage(this.side2, t2, "to", { opacity: 0, duration: 1 }, ">");
      changeImage(this.side2, t2, "set", { map: tex5 }, ">");
      changeImage(this.side2, t2, "to", { opacity: 1, duration: 1 }, ">");
      changeImage(
        this.side2,
        t2,
        "to",
        { opacity: 0, duration: 0.5, delay: delayTime },
        ">"
      );
      changeImage(this.side2, t2, "set", { map: tex4 }, ">");
      changeImage(this.side2, t2, "to", { opacity: 1, duration: 1 }, ">");
      changeImage(
        this.side2,
        t2,
        "to",
        { opacity: 0, duration: 0.5, delay: delayTime },
        ">"
      );
      changeImage(this.side2, t2, "set", { map: tex8 }, ">");
      changeImage(this.side2, t2, "to", { opacity: 1, duration: 1 }, ">");
      changeImage(this.side2, t2, "to", { opacity: 0, duration: 0.5 }, ">");

      // let t3 = gsap.timeline({repeat: -1, repeatDelay: 1});
      // changeImage(this.side3, t3, "to", {opacity: 0, duration:1}, ">")
      // changeImage(this.side3, t3, "set", {map: tex5}, ">")
      // changeImage(this.side3, t3, "to", {opacity: 1, duration:1}, ">")
      // changeImage(this.side3, t3, "to", {opacity: 0, duration:.5, delay: delayTime}, ">")
      // changeImage(this.side3, t3, "set", {map: tex4}, ">")
      // changeImage(this.side3, t3, "to", {opacity: 1, duration:1}, ">")
      // changeImage(this.side3, t3, "to", {opacity: 0, duration:.5, delay: delayTime}, ">")
      // changeImage(this.side3, t3, "set", {map: tex8}, ">")
      // changeImage(this.side3, t3, "to", {opacity: 1, duration:1}, ">")
      // changeImage(this.side3, t3, "to", {opacity: 0, duration:.5}, ">")

      // let t4 = gsap.timeline({repeat: -1, repeatDelay: 1});
      // changeImage(this.side4, t4, "to", {opacity: 0, duration:1}, ">")
      // changeImage(this.side4, t4, "set", {map: tex5}, ">")
      // changeImage(this.side4, t4, "to", {opacity: 1, duration:1}, ">")
      // changeImage(this.side4, t4, "to", {opacity: 0, duration:.5, delay: delayTime}, ">")
      // changeImage(this.side4, t4, "set", {map: tex4}, ">")
      // changeImage(this.side4, t4, "to", {opacity: 1, duration:1}, ">")
      // changeImage(this.side4, t4, "to", {opacity: 0, duration:.5, delay: delayTime}, ">")
      // changeImage(this.side4, t4, "set", {map: tex8}, ">")
      // changeImage(this.side4, t4, "to", {opacity: 1, duration:1}, ">")
      // changeImage(this.side4, t4, "to", {opacity: 0, duration:.5}, ">")
    },
    next: function() {
      window.webar_TTT.tracking.track_action('select_plane_letsdunk');
      this.courtSnd.play();
      router.push("select");
    },
    reCenter: function() {
      console.log("RECENTER");
      this.scene.emit("recenter", {});
    },
    setStage: function() {
      console.log("setStage");
      window.webar_TTT.tracking.track_action('select_plane_set');
      if (this.stageInit) {
        this.reCenter();
        return;
      }
      this.stageInit = true;
      clearInterval(this.timeRecenter);
      try {
        // if (XR8 && XR8.Threejs.xrScene()) {
        //   const { scene, camera, renderer } = XR.Threejs.xrScene();
        console.log("recenter");
        let tl = gsap.timeline();
        this.reCenter();

        this.courtSnd.play();
        this.side1.components.sound.volume = 1
        this.side1.components.sound.playSound();

        tl.to(this.tap, { duration: 0.5, autoAlpha: 0 });
        tl.to(this.$refs.cover, { duration: 0.5, autoAlpha: 0 }, "<");

        animateModel(this.model1, tl, "to", { opacity: 1, duration: 1 }, "<");
        animateModel(this.court, tl, "to", { opacity: 1, duration: 1 }, "<");
        animateModel(this.side1, tl, "to", { opacity: 1, duration: 1 }, "<");
        animateModel(this.side2, tl, "to", { opacity: 1, duration: 1 }, "<");
        // animateModel(this.side3, tl, "to", {opacity: 1, duration:1}, "<")
        // animateModel(this.side4, tl, "to", {opacity: 1, duration:1}, "<")

        this.side1.setAttribute("visible", true);
        this.side2.setAttribute("visible", true);
        // this.side3.setAttribute("visible",true);
        // this.side4.setAttribute("visible",true);

        tl.to(this.letsdunk, {
          duration: 0.5,
          delay: 1,
          autoAlpha: 1,
          ease: "power2.out",
        });
        tl.to(this.letsdunk, { duration: 2, y: 0, ease: "bounce.out" });

        this.courtsideAnim();
      } catch (e) {
        console.log("ERROR");
      }
    },
  },
};
</script>
