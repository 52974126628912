
import store from "../Store";

function animateModel(model, tlObj, type, prop, end) {
  let tempModel = model.getObject3D('mesh');
  // console.log('1  tempModel   ', tempModel, model);
  if (tempModel === undefined) {
    return;
  }
  tempModel.traverse((node) => {
    if (node.isMesh) {
      // console.log('prop   ', prop);
      tlObj[type](node["material"], prop, end);
    }
  });
}
function changeImage(model, tlObj, type, prop, end) {
  let tempModel = model.getObject3D('mesh');
  // console.log('1  tempModel   ', tempModel, model);
  if (tempModel === undefined) {
    return;
  }
  tempModel.traverse((node) => {
    if (node.isMesh && node.material.name === 'board') {
      // console.log('prop   ', prop);
      tlObj[type](node["material"], prop, end);
    }
  });
}


function modelFix(model) {
  let tempModel = model.getObject3D('mesh');
  // console.log('2  tempModel   ', tempModel);
  if (tempModel === undefined) {
    return;
  }
  tempModel.traverse((node) => {
    if (node.isMesh) {
      // node.geometry.computeBoundingBox();
      node.material.transparent = true;
      node.frustumCulled = false;
      // node.geometry.boundingBox.expandByScalar(2);
    }
  });
}

function resetAnimation() {
  const tony = document.getElementById("model0");
  tony.removeAttribute("gltf-model");
  tony.setAttribute("gltf-model", './assets/models/hoop.gltf');
  tony.setAttribute("animation-mixer", { timeScale: 0 });
}

// function modelFixScene(model) {
//   let tempModel = model.getObject3D('mesh');
//   console.log('2  tempModel   ', tempModel);
//   if (tempModel === undefined) {
//     return;
//   }
//   scene.traverse(function (object) {

//     object.frustumCulled = false;

//   })
// }
function RAND(min, max, decimalPlaces = 5) {
  // return Math.floor(Math.random() * (max + 1)) + min;
  var rand = Math.random()*(max-min) + min;
  var power = Math.pow(10, decimalPlaces);
  return Math.floor(rand*power) / power;
}

function MobileVer() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  console.log('useragent', userAgent)
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

function externalLink() {
  let group = store.state.group;
  let url = 'https://tonytheTiger.com'
  let stage = 'www.';// stage65-engage.
  if (group.shaq === '0') {
    if (group.id === '0') {
      url = `https://${stage}tonytheTiger.com/en_US/post-kids.html`;
    } else if (group.id === '1') {
      url = `https://${stage}tonytheTiger.com/en_US/post-main.html`;
    } else if (group.id === '2') {
      url = `https://${stage}tonytheTiger.com/en_US/post-full.html`;
    }
  } else if (group.shaq === '1') {
    if (group.id === '0') {
      url = `https://${stage}tonytheTiger.com/en_US/post-shaq-kids.html`;
    } else if (group.id === '1') {
      url = `https://${stage}tonytheTiger.com/en_US/post-shaq-main.html`;
    } else if (group.id === '2') {
      url = `https://${stage}tonytheTiger.com/en_US/post-shaq-full.html`;
    }
  }

  return url;
}
function addAudio(file){
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  const audio = new Audio(file);
  const source = audioCtx.createMediaElementSource(audio);
  source.connect(audioCtx.destination);
  return source;
}
function Interpolate(start, end, steps, count) {
  var s = start,
    e = end,
    final = s + (((e - s) / steps) * count);
  return Math.floor(final);
}
function convertColorToPoint(uNum) {
  return (100 / 360 * .01) * uNum;
}
function Color(_r, _g, _b) {
  var r, g, b;
  var setColors = function (_r, _g, _b) {
    r = _r;
    g = _g;
    b = _b;
  };

  setColors(_r, _g, _b);
  this.getColors = function () {
    var colors = {
      r: r,
      g: g,
      b: b
    };
    return colors;
  };
}

export {
  animateModel,
  modelFix,
  resetAnimation,
  changeImage,
  RAND,
  MobileVer,
  Interpolate,
  Color,
  convertColorToPoint,
  externalLink,
  addAudio
}