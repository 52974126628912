import { Group } from 'three';
import * as THREE from 'three';

import { RAND } from '../helper';
import Fireworks from './Fireworks.js';

export default class FireworkShow extends Group {
  constructor(pos) {
    
    super();
    this.fireworks = [];
    this.gravity = new THREE.Vector3(0, -2/1000000, 0);
    this.pos = pos;
    this.offSetX = 4;
    this.offSetY = 1;
    this.offSetZ = 0;
    this.count = 5;
    this.show = false;
    this.startend = this.startshow;
    // this.fireworks.push(new Fireworks());
  }
  startshow(on = true){
    this.show = on;
    console.log('SHOW IS :', on);
			
    // for (let i = 0; i < this.count; i++) {
    //   let fs = new Fireworks();
    //   fs.explode({
    //     x: RAND(this.pos.x-this.offSet, this.pos.x+this.offSet), 
    //     y:RAND(this.pos.y-this.offSet, this.pos.y+this.offSet), 
    //     z:RAND(this.pos.z-this.offSet, this.pos.z+this.offSet)});
    //   this.fireworks.push(fs);
    // }
  }
  update(){
    if(Math.random(1) < 0.3 && this.show) {
      let fs = new Fireworks();
      fs.explode({
        x: RAND(this.pos.x-this.offSetX, this.pos.x+this.offSetX), 
        y:RAND(this.pos.y-this.offSetY, this.pos.y+this.offSetY), 
        z:RAND(this.pos.z-this.offSetZ, this.pos.z+this.offSetZ)});
      this.fireworks.push(fs);
      // console.log('this.fireworks   MESSSSHHHHHHH ', this.fireworks);
    }
    if(this.fireworks.length > 0){
      for(let i=0;i < this.fireworks.length; i++){
        if(!this.fireworks[i].done){
          this.fireworks[i].update();
          this.fireworks[i].show();
          this.add(this.fireworks[i]);
        } else {
          this.remove(this.fireworks[i]);
          this.fireworks.splice(i, 1)
        }
      }
    }
  }
}
