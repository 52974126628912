<template>
  <div class="webAr pagefull" v-if="shouldShow3dView">
    <v-orientation v-if="landscape" />
    <router-view class="popover"></router-view>
    <a-scene
      id="scene"
      vr-mode-ui="enabled: false"
      sortObjects: true
      raycaster="objects: .cantap"
      renderer="maxCanvasWidth: 720; 
      maxCanvasHeight: 960; 
      antialias: true;"
      device-orientation-permission-ui="enabled: false"
      render-setting
      xrextras-gesture-detector
      xrextras-almost-there
      xrextras-loading
      xrweb="allowedDevices:any"
      :stats="showStats"
       >
      <a-assets>
        <!-- <audio id="river" src="./assets/sound/crowd.mp3" preload="auto"></audio>
        <a-asset-item response-type="arraybuffer" src="./assets/models/tony/dunk1.gltf"></a-asset-item>
        <a-asset-item response-type="arraybuffer" src="./assets/models/tony/dunk2.gltf"></a-asset-item>
        <a-asset-item response-type="arraybuffer" src="./assets/models/tony/dunk3.gltf"></a-asset-item> -->
      </a-assets>

      <!--   shaq_tony_static.glb      position="0 5 0" The raycaster will emit mouse events on scene objects specified with the cantap class -->
      <a-camera
        id="camera"
        position="0 8 8"
        near="0.001"
        raycaster="objects: .cantap"
        cursor="
          fuse: false;
          rayOrigin: mouse;"
      >
      </a-camera>
      
      <a-entity
        light="type: directional;
         intensity: 1.0;
         castShadow: true;
         shadowMapHeight:2048;
         shadowMapWidth:2048;
         shadowCameraTop: 10;
         target: #model;"
        position="1 4.3 2.5"
        xrextras-attach="target: model; offset: 1 15 3;"
        shadow
      >
      </a-entity>

      <a-light type="ambient" intensity="0.8  "></a-light>
      
      <a-entity
        id="model"
        class="cantap"
        rotation="0 -120 0"
        scale="1.25 1.25 1.25"
        xrextras-hold-drag
        xrextras-two-finger-rotate
        xrextras-pinch-scale
        cursor
        shadow
      >
      <a-entity
        id="fireworks"
        name="particles"
        position="0 5 0"
        sound="src: url(./assets/sound/fireworks1.mp3); autoplay:false; positional:false"
        modify-sound
        foo
      ></a-entity>
        <a-entity
          id="model0"
          position="0 0 -2"
          gltf-model="./assets/models/shaq_tony_static.glb"
          animation-mixer="clip:*; timeScale: 1; crossFadeDuration: 1; positional:false"
          modify-sound
          visible="false"
          modify-materials
          shadow
        ></a-entity>
        <a-entity
          id="model1"
          position="0 0 0"
          gltf-model="./assets/models/court_t.glb"
          visible="false"
          shadow
        ></a-entity>
        <a-entity
          id="side1"
          class="sideboard"
          position="-8 0 6.5"
          gltf-model="./assets/models/courtside_d.glb"
          scale="0.75 .75 .75"
          sound="src: url(./assets/sound/crowd3.m4a); autoplay:false; loop:true; positional:false"
          visible="false"
          modify-sound
          shadow
        ></a-entity>
        <a-entity
          id="side2"
          class="sideboard"
          position="8 0 .3"
          gltf-model="./assets/models/courtside_d.glb"
          sound="src: url(./assets/sound/crowd_cheer.m4a); autoplay:false; positional:false"
          scale="0.75 .75 .75"
          rotation="0 180 0"
          visible="false"
          shadow
        ></a-entity>
      </a-entity>
      
      <a-plane
        id="ground"
        position="0 -1 0"
        rotation="-90 0 0"
        width="1000"
        height="1000"
        material="shader: shadow"
        sound="src: url(./assets/sound/crowd2.m4a); autoplay:false; positional:false"
        shadow
      ></a-plane>
     <a-entity id="talk" sound="src: url(./assets/sound/crowd2.m4a); autoplay:false; positional:false"></a-entity>
     <a-entity id="countNum" sound="src: url(./assets/sound/count_snd.mp3); autoplay:false; positional:false"></a-entity>
     <a-entity id="countNumEnd" sound="src: url(./assets/sound/count_end_snd.mp3); autoplay:false; positional:false"></a-entity>
     <xrextras-capture-button
        capture-mode="standard"
      ></xrextras-capture-button>

      <xrextras-capture-config
        watermark-image-url="./assets/images/watermark.png"
        watermark-max-width="100"
        watermark-max-height="100"
        watermark-location="topLeft"
        max-duration-ms="40000"
        max-dimension="500"
        enable-end-card="false"
        request-mic="manual"
        file-name-prefix="my-capture-"
      ></xrextras-capture-config>

      <xrextras-capture-preview
        action-button-share-text="Share"
        action-button-view-text="View"
      ></xrextras-capture-preview>

    </a-scene>
      <audio id="courtSnd">
        <source src="@/assets/sound/click.mp3" type="audio/mpeg" />
      </audio>
    <audio id="countSnd">
      <source src="" type="audio/mpeg" />
    </audio>
    <audio id="countSndEnd">
      <source src="" type="audio/mpeg" />
    </audio>
  </div>
</template>
<style lang="scss">
#hudMain {
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  margin: 10px auto;
}
.recorder-container {
  // background-color: red;
}
.playbut {
  width: 115%;
  height: 50px;
}

.popover {
  width: 100%;

  z-index: 3000;
  margin: 0 auto;
  height: 100%;
  // background-color: rebeccapurple;
  // display: flex;
  position: relative;
  position: absolute;
  bottom: 0;
  pointer-events: none;
}
</style>
<script>

// import { gsap } from "gsap";
// import { changeImage } from "@/lib/helper";
// import './utils/WebRTCFix.js';
// import getPipelineModule from './lib/getPipelineModule';
import store from "../Store";
import Orientation from "@/components/Orientation";

// @ is an alias to /src
// physics-image-target="name: tonytiger"
export default {
  name: "Home",
  components: {
    "v-orientation": Orientation
  },
  data: function() {
    return {
      showStats: false,
      shouldShow3dView: true,
      tony: null,
      hoop: null,
      floor: null,
      animationList: ["All Animations", "a"],
      idx: 0,
      playSlowMo: false,
      playItem: null,
      landscape: false
      // tonyModel: require('./assets/models/tony_ball_dunk1.glb')
    };
  },
  methods: {
    showModel: function() {
      this.tony = document.getElementById("model0");
      this.hoop = document.getElementById("model1");
      this.floor = document.getElementById("model2");
      console.log("showModel  ", this.shoes);
    },
    orientationChange: function(){
      if (window.innerWidth > window.innerHeight) {
        this.landscape = true;
        store.landscape = true;
        console.log('landscape True');
      } else {
        console.log('landscape FALSE');
        this.landscape = false;
        store.landscape = false;
      }
    }
  },
  mounted: function() {
    window.addEventListener("resize", this.orientationChange);
    this.showModel();
  },
  unmounted: function() {
    window.removeEventListener("resize", this.orientationChange);
  },
};
</script>
