<template>
  <transition
    appear
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="Select">
      <v-carousel
        :cards="state.player === 0 ? tonycards : shaqcards"
        :onSelect="next"
      />
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.Brand {
  //   position: fixed;
  //   width:80%;
  //   margin:0 auto;
  // bottom: 0;
  // z-index:2000;
  position: absolute;
  bottom: -90px;
  align-self: flex-end;
  justify-self: flex-end;
}
.Select {
  text-align: center;
  width: 100%;
  pointer-events: auto;
}
#countdown {
  position: absolute;
  text-align: center;
  top: 85px;
  width: 100%;
  font-size: 10rem;

  z-index: 2000;
}
</style>
<script>
// @ is an alias to /src
import { gsap } from "gsap";

import store from "../Store";
import router from "../router/index";
import Carousel from "@/components/Carousel";
// import { Back, Power2, Power3 } from "gsap/EasePack";
// import { CSSPlugin } from "gsap/CSSPlugin";
// import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer'

// physics-image-target="name: tonytiger"
export default {
  name: "Select",
  components: {
    "v-carousel": Carousel,
  },
  data: function () {
    return {
      init: gsap.timeline(),
      state: store.state,
      tonycards: [
        {
          id: 1,
          name: "Flying Tiger",
          title: "Choose Tony's Jam",
          image: "images/Photo/01_Tony_Carousel.png",
          background: "images/Photo/bg_tony.png",
          model: "./assets/models/tony/dunk1.glb",
          sound: "./assets/sound/tony_dunk1b_widetrim.m4a",
          tag: "tony_jam_select_1",
          cMainId: 0,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          fireworks: 7.5
        },
        {
          id: 2,
          name: "Tail-y Oop",
          title: "Choose Tony's Jam",
          image: "images/Photo/02_Tony_Carousel.png",
          background: "images/Photo/bg_tony.png",
          model: "./assets/models/tony/dunk2.glb",
          sound: "./assets/sound/tony_dunk2_wide.m4a",
          tag: "tony_jam_select_2",
          cMainId: 1,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          fireworks: 8.5,
          left: "-20px"
        },
        {
          id: 3,
          name: "Over the Mountain",
          title: "Choose Tony's Jam",
          image: "images/Photo/03_Tony_Carousel.png",
          background: "images/Photo/bg_tony.png",
          model: "./assets/models/tony/dunk3.glb",
          sound: "./assets/sound/tony_dunk3_dance.m4a",
          tag: "tony_jam_select_3",
          cMainId: 2,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          tony: null,
          fireworks: 8
        },
      ],
      shaqcards: [
        {
          id: 1,
          name: "Signature Shaq",
          title: "Choose Shaq's Jam",
          image: "images/Photo/01_Shaq_Carousel.png",
          background: "images/Photo/bg_shaq.png",
          model: "./assets/models/shaq/dunk1.glb",
          tag: "shaq_jam_select_1",
          sound: "./assets/sound/shaq_dunk_1.m4a",
          cMainId: 0,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          fireworks: 7.7
        },
        {
          id: 2,
          name: "Backboard Beware",
          title: "Choose Shaq's Jam",
          image: "images/Photo/02_Shaq_Carousel.png",
          background: "images/Photo/bg_shaq.png",
          model: "./assets/models/shaq/dunk2.glb",
          tag: "shaq_jam_select_2",
          sound: "./assets/sound/shaq_dunk_2.m4a",
          cMainId: 1,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          fireworks: 9.5
        },
        {
          id: 3,
          name: "The Big Put Back",
          title: "Choose Shaq's Jam",
          image: "images/Photo/03_Shaq_Carousel.png",
          background: "images/Photo/bg_shaq.png",
          model: "./assets/models/shaq/dunk3.glb",
          tag: "shaq_jam_select_3",
          sound: "./assets/sound/shaq_dunk_3.m4a",
          cMainId: 2,
          distFromParentCenter: 0,
          startLeftDist: 0,
          isVisible: true,
          fireworks: 10.5
        },
      ],
    };
  },
  mounted() {
    console.log("MultiSelect mounted!");
    window.webar_TTT.tracking.track_gamestate('choose_jam');
    this.tony = document.getElementById("model0");
  },
  updated() {
    console.log("updated!");
  },
  methods: {
    beforeEnter(el) {
      // gsap.set(el, { opacity: 0 });
      console.log("beforeEnter el  ", el);
    },
    enter(el, done) {
      // gsap.to(el, 1, { opacity:1, onComplete:done });
      console.log("enter el  ", el);
      done();
    },
    leave(el, done) {
      // gsap.to(el, 1, { opacity:0, onComplete:done });
      console.log("leave el  ", el);
      done();
    },
    selectTalk: function(){
      let selectedTalk = null;
      let currSelect = null;
      if(this.state.player === 0){
        currSelect = this.state.tony_talk_selected;
        selectedTalk = this.state.tonyTalkArr[currSelect];

        if((currSelect+1) >= this.state.tonyTalkArr.length) {
          store.tony_talk_selected = 0;
        } else {
          store.tony_talk_selected = currSelect + 1;
        }
      } else {
        currSelect = this.state.shaq_talk_selected;
        selectedTalk = this.state.shaqTalkArr[currSelect];

        if( (currSelect+1) >= this.state.shaqTalkArr.length) {
          store.shaq_talk_selected = 0;
        } else {
          store.shaq_talk_selected = currSelect + 1;
        }
      }
      store.talk_active = selectedTalk;
      console.log('store.talk_active', this.state.talk_active);
      console.log('store.tony_talk_selected', this.state.tony_talk_selected);
      console.log('store.shaq_talk_selected', this.state.shaq_talk_selected);
      router.push("play");
    },
    next: function (card) {
      console.log("PLAY");
      console.log(card.name);
      console.log(card);
      window.webar_TTT.tracking.track_action(card.tag);
      store.dunk = card;
      this.selectTalk();
    },
  },
};
</script>
