<template>
  <div class="footer">
    <audio id="photoaudio">
      <source src="@/assets/sound/click2.mp3" type="audio/mpeg" />
    </audio>
    <div class="footer__wrapper">
      <div class="footer__item">
        <button
          id="photobut"
          class="footer__button tempDisable"
          :onClick="capturePhoto"
          v-bind:class="recordingInit || captureComplete ? 'disabled' : ''"
        >
          <img :src="require('@/assets/images/UI/Camera.svg')" alt="Photo" />
          <span>Take Photo</span>
        </button>
      </div>
      <div class="footer__item">
        <div class="switch" :onClick="slowmo">
          <img
            class="lever"
            id="lever"
            :src="require('@/assets/images/Icon/ball_button_no_text.png')"
            alt="1"
          />
          <img
            class="lever_bg"
            :src="require('@/assets/images/Icon/switch_bg.png')"
            alt="1"
          />
        </div>
      </div>
      <div class="footer__item">
        <button
          id="recordbut"
          class="footer__button tempDisable"
          :onClick="recordStartStop"
          v-bind:class="captureComplete ? 'disabled' : ''"
        >
          <!-- <img class="recordbut" :src="require('@/assets/images/UI/RecordButton.svg')" alt="test" />
          <svg viewBox="0 0 38 38" class="record-wrapper">
            <circle class="track" r="16" cx="19" cy="19"></circle>
            <circle id="progress_record" class="progress-bar" r="16" cx="19" cy="19"></circle>
            <circle class="loading-record" r="16" cx="19" cy="19"></circle>
          </svg>-->
          <svg
            id="recordCircle"
            width="40px"
            height="40px"
            viewBox="0 0 40 40"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>UI / Record Button</title>
            <g
              id="Symbols"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Component-/-Filming-UI"
                transform="translate(-294.000000, -200.000000)"
              >
                <g id="Group-3" transform="translate(294.000000, 200.000000)">
                  <circle
                    id="record_outline"
                    stroke="#FFFFFF"
                    stroke-width="3"
                    cx="20"
                    cy="20"
                    r="18.5"
                  ></circle>
                  <circle
                    id="record_process"
                    stroke="#e17a4b"
                    stroke-width="3"
                    cx="20"
                    cy="20"
                    r="18.5"
                  ></circle>
                  <circle
                    id="record_dot"
                    fill="#FFFFFF"
                    cx="20"
                    cy="20"
                    r="14"
                  ></circle>
                </g>
              </g>
            </g>
          </svg>
          <span>Start Recording</span>
        </button>
      </div>
      <transition name="footer__fade">
        <div v-show="showInstructions" class="footer__item">
          <v-instructions
            :isHidden="true"
            :isIconHidden="true"
            :isButtonHidden="true"
            kind="small"
          >
            Take Photo
          </v-instructions>
        </div>
      </transition>
      <transition name="footer__fade">
        <div v-show="showInstructions" class="footer__item">
          <v-instructions
            :isHidden="true"
            :isIconHidden="true"
            :isButtonHidden="true"
            kind="small"
          >
            Toggle Slow Motion
          </v-instructions>
        </div>
      </transition>
      <transition name="footer__fade">
        <div v-show="showInstructions" class="footer__item">
          <v-instructions
            :isHidden="true"
            :isIconHidden="true"
            :isButtonHidden="true"
            kind="small"
          >
            Start Recording
          </v-instructions>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);
import Instructions from "@/components/Instructions";
//progressBar.style.strokeDashoffset = `${100 * timeLeft}`progressBar
import store from "../Store";

export default {
  name: "Footer",
  components: {
    "v-instructions": Instructions,
  },
  data: function () {
    return {
      slowmoOn: false,
      playItem: null,
      animationList: ["All Animations", "a"],
      idx: 0,
      tony: null,
      showInstructions: true,
      recordingInit: false,
      recording: false,
      captureComplete: false,
      state: store.state,
      recordItem: null,
      photoItem: null,
      tl: null,
      photoAudio: null,
    };
  },
  methods: {
    slowmo() {
      this.slowmoOn = !this.slowmoOn;

      window.webar_TTT.tracking.track_action("slomo_"+ this.slowmoOn ? "on" : "off");
      gsap.to("#lever", { duration: 0.2, x: this.slowmoOn ? 70 : 1 });
      this.play();
      console.log("SLOWMO  ", this.slowmoOn);
    },
    capturePhoto() {
      
      window.webar_TTT.tracking.track_action("take_photo");
      console.log("capturePhoto     ");
      this.photoAudio = document.getElementById("photoaudio");
      this.photoAudio.volume = 1;
      this.photoAudio.play();
      let tl = gsap.timeline();
      tl.set(".photoNote", { scale: 0.5 });
      tl.to(".photoNote", { duration: 1, autoAlpha: 1, scale: 1 });
      tl.to(".photoNote", { duration: 1, autoAlpha: 0, delay: 2 });

      this.recordItem.classList.add("disabled");
      this.photoItem.classList.add("disabled");
      store.capture = "image";
      this.captureComplete = true;
      window.dispatchEvent(new CustomEvent("mediaphoto-capture"));
    },
    startStopAnimation(start = true) {
      let item = ".recordbut";
      if (start) {
        gsap.to(item, {
          duration: 0.5,
          autoAlpha: 1,
          scale: 1.5,
          repeat: -1,
          yoyo: true,
          overwrite: true,
        });
      } else {
        gsap.to(item, {
          duration: 0.5,
          autoAlpha: 1,
          scale: 1.0,
          overwrite: true,
        });
      }
    },
    recordStartStop() {
      console.log("recordStartStop  1", this.recording);
      console.log("recordingInit  1", this.recordingInit);
      window.webar_TTT.tracking.track_action("take_video");
      this.photoItem.classList.add("disabled");
      if (this.recording && this.recordingInit) {
        console.log("recordStartStop END  ");
        this.recording = false;
        this.captureComplete = true;

        gsap.to("#record_process", {
          duration: 0.2,
          drawSVG: "0%",
          autoAlpha: 0,
          ease: "power1.inOut",
          overwrite: true,
        });
        gsap.to("#record_dot", {
          duration: 0.2,
          fill: "rgb(255,255,255)",
          ease: "power1.inOut",
          overwrite: true,
        });
        this.recordItem.classList.add("disabled");
        this.startStopAnimation(false);
        window.dispatchEvent(new CustomEvent("mediarecorder-endcapture"));
      } else {
        if (!this.recordingInit) {
          console.log("recordStartStop Start  ");

          gsap.to("#record_process", {
            duration: 45,
            drawSVG: "100%",
            ease: "power1.inOut",
          });
          gsap.to("#record_dot", {
            duration: 1,
            fill: "rgb(225,122,75)",
            ease: "power1.inOut",
          });
          store.capture = "video";
          this.recordingInit = true;
          this.recording = true;
          this.startStopAnimation();
          window.dispatchEvent(new CustomEvent("mediarecorder-startcapture"));
        }
      }
      console.log("recordStartStop  2", this.recording);
      console.log("recordingInit  2", this.recordingInit);
    },
    playAnimation: function (name, speed) {
      this[name].setAttribute("animation-mixer", {
        timeScale: speed,
      });
      this.soundAll(speed);
    },
    soundAll: function (speed) {
      // this[name].setAttribute("sound", {
      //   getPlaybackRate: this.state.slowmo
      // });
      if (speed === 0.99) {
        speed = 1;
      }
      let entityArr = document.querySelectorAll("[sound]");
      console.log(entityArr.length, "entityArr   ", entityArr);
      for (let q = 0; q < entityArr.length; q++) {
        let entity = entityArr[q];
        console.log("entity  ", entity.components.sound);
        console.log(
          "entity .pool.children.length ",
          entity.components.sound.pool
        );
        let sound = null;
        for (let i = 0; i < entity.components.sound.pool.children.length; i++) {
          console.log("SOUND>>>>>>>>>>>>", entity.components.sound.pool);
          console.log("SOUND>>>>>>>>>>>>", entity.components.sound.pool.el.getAttribute('id'));
          let idName = entity.components.sound.pool.el.getAttribute('id');
          if(idName != 'talk'){
            sound = entity.components.sound.pool.children[i];
            sound.setPlaybackRate(speed);
          }
        }
      }
    },
    play: function () {
      let speed = 0.99;

      if (this.slowmoOn) {
        speed = 0.25;
      }
      store.slowmo = speed;
      console.log("PLAY>>>>>>>>>>");
      this.playAnimation("tony", speed);
    },
    hideInstructions: function () {
      this.showInstructions = false;
      console.log(this.showInstructions);
    },
  },
  mounted: function () {
    store.capture = null;
    store.slowmo = 0.99;
    this.recordItem = document.getElementById("recordbut");
    this.photoItem = document.getElementById("photobut");
    gsap.to("#record_process", {
      duration: 0.2,
      drawSVG: "0%",
      autoAlpha: 0,
      ease: "power1.inOut",
      overwrite: true,
    });
    gsap.to("#record_dot", {
      duration: 0.2,
      fill: "rgb(255,255,255)",
      ease: "power1.inOut",
      overwrite: true,
    });
    setTimeout(() => {
      this.hideInstructions();
    }, 5000);
    // setTimeout(() => {
    //   gsap.to("#loadingContainer", {autoAlpha:0})
    // }, 2000);
    gsap.set("#recordCircle", { rotation: -90 });
    gsap.set("#record_process", { drawSVG: "0%" });
    window.dispatchEvent(new CustomEvent("mediarecorder-initcapture"));
    this.tony = document.getElementById("model0");
  },
  unmounted: function () {
    this.soundAll(0.99)
    if (this.recording && this.recordingInit) {

      this.startStopAnimation(false);
      window.dispatchEvent(new CustomEvent("mediarecorder-endcapture"));
    }
  },
};
</script>
<style scoped lang="scss">
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 180px;
  background: url("/assets/images/Icon/Lower_Third_Dots.png") bottom left
    no-repeat;

  &__wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    position: absolute;
    bottom: 0;
    height: 128px;
    width: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 32%;
    height: 64px;
    text-align: center;
  }

  &__button {
    border: 0px;
    background-color: transparent;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    // border: solid 1px $white;
    // padding: 2px;
    background-clip: content-box;
    z-index: 1000;
    pointer-events: auto;
    span {
      display: none;
    }
  }
  .disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
  }
  &__fade-enter-active,
  &__fade-leave-active {
    transition: opacity 0.5s ease-in-out 7s;
  }

  &__fade-enter,
  &__fade-leave-to {
    opacity: 0;
  }
}
.lever {
  position: absolute;
  width: 60px;
  z-index: 2000;
  left: 1px;
  // top: -54px;
}
.lever_bg {
  left: 0;
  position: absolute;
}
.centerme {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.switch {
  position: relative;
  width: 133px;
  height: 64px;
  // margin: 60px auto 0 auto;
  pointer-events: auto;
}
.progress-container {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  transition: 0.3s transform, 0.3s opacity;
}

.track,
.progress-record,
.loading-record {
  fill: transparent;
  stroke-width: 3;
}

.track {
  stroke: white;
}

.progress-record {
  opacity: 0;
  transition: 0;
  transform-origin: 50% 50%;
  stroke: white;
  transform: rotate(-90deg);
  stroke-dasharray: 100.531 100.531;
  stroke-dashoffset: 100;
}

.record-wrapper {
  opacity: 1;
  transform: scale(1);
}

.recording .progress-track,
.loading .progress-track {
  transition: 0.8s stroke;
  stroke: #fff5;
}

.recording .progress-bar {
  opacity: 1;
}
</style>
<style lang="scss">
.footer__item .helper {
  margin: 0;
}
</style>