<template>
  <div class="exit pagefull">
    <div class="exit__overlay"></div>
    <h2>All done jamming?</h2>
    <v-button :onClick="leavesite" kind="button__primary">
       <template v-slot:header>Exit to</template>
      Main site
    </v-button>
    <v-button :onClick="close" kind="button__secondary">
      <template v-slot:header>Resume</template>
      Game
    </v-button>
  </div>
</template>

<script>

import { externalLink } from "@/lib/helper";
import router from "../router/index";
import Button from "@/components/Button";
export default {
  components: {
    "v-button": Button,
  },
  mounted (){
    window.webar_TTT.tracking.track_gamestate('close_game');
  },
  methods: {
    close() {
      console.log("Button click.");
      window.webar_TTT.tracking.track_action('close_game_backtogame');
      router.push('select')
    },
    leavesite() {
      console.log("Button click.");
      window.webar_TTT.tracking.track_action('close_game_exit');
      window.location.href = externalLink();

    },
  },
};
</script>

<style lang="scss" scoped>
.exit {
  background-color: $black;
  background-size: cover;
  background-image: url("~@/assets/images/Photo/tony.png");
  background-repeat: no-repeat;
  color: $white;
  display: flex;
  flex-direction: column;
  // grid-template-rows: repeat(1, 1fr);
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0 26px;
  position: relative;

  > * {
    margin-bottom: 20px;
    z-index: 3;
  }

  &__overlay {
    background-color: $black20;
    backdrop-filter: blur(20px);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}
</style>