<template>
  <div class="permission-error-p pagefull">
    <div
      class="permission-error-p__overlay"></div>
    <div class="permission-error-p__message">
      <div>
        <h2>We need your permission</h2>
        <div class="permission-error-p__message__info">
          <img
            :src="require('@/assets/images/Icon/Camera.png')"
            :srcset="require('@/assets/images/Icon/Camera@2x.png') + ' 2x'"
            alt="Access Camera"
            width="32"
            height="32"
          />
          <p>Grant access to your phone camera</p>
          <img
            :src="require('@/assets/images/Icon/AR.png')"
            :srcset="require('@/assets/images/Icon/AR@2x.png') + ' 2x'"
            alt="Access AR"
            width="32"
            height="32"
          />
          <p>Grant access to motion & orientation</p>
        </div>
      </div>
      <div class="permission-error-p__message__text">
        <p>
          Your permission is needed to access this portion of the experience.
          Please reload the page and try again.
        </p>
        <p>
          If you continue to have issues, clear your history and website data.
        </p>
      </div>
      <div class="permission-error-p__message__buttons">
        <v-button :onClick="reload" kind="button__tertiary">
          <img :src="require('@/assets/images/Icon/Reload.svg')" alt="Reload" />
          Reload
        </v-button>
        <v-link-exit />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import LinkExit from "@/components/LinkExit";

import store from "../Store";
// import Permissions from "@/lib/Permissions";
// import router from "../router/index";
export default {
  components: {
    "v-button": Button,
    "v-link-exit": LinkExit,
  },
  mounted() {
    window.webar_TTT.tracking.track_gamestate('permission_error');
  },
  methods: {
    reload() {
      console.log("Button click.");
      window.webar_TTT.tracking.track_action('permission_error_reload');
      // window.location.reload(true);
      // var versionUpdate = (new Date()).getTime();
      let url = `https://${window.location.hostname}${window.location.port != '' ? ":" + window.location.port : ''}/#/group/${store.state.group.id}/${store.state.group.shaq}`;

      console.log('url   ', url);
      window.open(url, '_blank');
      // Permissions.permissions();
    }
  },
};
</script>
<style lang="scss" scoped>
.permission-error-p {
  align-items: center;
  background-color: $black;
  background-size: cover;
  background-image: url("~@/assets/images/Photo/SplashArt.jpg");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  color: $white;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  justify-items: center;

  &__overlay {
    background-color: $black20;
    backdrop-filter: blur(5px);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  &__message {
    color: $white;
    margin: 0;
    width: 100%;
    z-index: 3;
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 50px 26px;
    justify-content: space-between;
    height: 100%;

    > * {
      margin-bottom: 10px;
      width: 100%;
    }

    &__info {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 20px;

      > * {
        margin-left: 5px;

        &:last-child {
          margin-left: 0;
        }
      }
    }

    &__text {
      text-align: center;

      > p:first-child {
        margin-bottom: 20px;
      }
    }

    &__buttons {
      text-align: center;

      > *:first-child {
        margin-bottom: 20px;
      }

      .button__tertiary img {
        padding-right: 10px;
      }
    }
  }
}
</style>